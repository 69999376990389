import { boolStr } from "../../util/params";
import { ApiId, DatasetModel, ExplorationModel, ExplorationSelection, JobInfoModel, ProcessorModel, ProjectModel, PromptModel } from "../apimodels";
import { standardDeleteOptions, standardGetOptions, standardPostOptions, standardPutOptions, throwApiError } from "../helpers";
import { ApiBatchResult, FetchFn, IProjectApi, SignedLinkFn } from "../types";

export class ProjectApi implements IProjectApi {
  private baseUrl: string;
  private fetchFn: FetchFn;
  private signedLinkFn: SignedLinkFn;
  public ver: number;

  constructor(baseUrl: string, fetchFn: FetchFn | undefined, signedLinkFn: SignedLinkFn | undefined) {
    this.baseUrl = baseUrl;
    this.fetchFn = fetchFn ?? ((input, init) => fetch(input, init));
    this.signedLinkFn = signedLinkFn ?? (async (url) => (url));
    this.ver = Math.random() * 10000000;
  }

  async fetchProject(id: ApiId): Promise<ProjectModel> {
    const url = `${this.baseUrl}/project/${id}`;
    const response = await this.fetchFn(url, standardGetOptions());
    const data = await response.json();
    if (!response.ok) {
      throwApiError(response.status);
    }
    return data as ProjectModel;
  }

  async signedLink(url: string): Promise<string> {
    return this.signedLinkFn(url);
  }

  async fetchDataset(projectId: ApiId, datasetId: ApiId): Promise<DatasetModel> {
    const url = `${this.baseUrl}/project/${projectId}/dataset/${datasetId}`;
    const response = await this.fetchFn(url, standardGetOptions());
    const data = await response.json();
    if (!response.ok) {
      throwApiError(response.status);
    }
    return data as DatasetModel;
  }

  async fetchDatasets(projectId: ApiId, datasetId?: ApiId): Promise<ApiBatchResult<DatasetModel>> {
    const url = `${this.baseUrl}/project/${projectId}/dataset` + (datasetId ? `?show_versions=true&dataset_id=${datasetId}` : "");
    const response = await this.fetchFn(url, standardGetOptions());
    const data = await response.json();
    if (!response.ok) {
      throwApiError(response.status);
    }
    const items = data as DatasetModel[];
    return {
      empty: items.length === 0,
      count: items.length,
      items,
    };
  }

  async createDataset(projectId: ApiId, data: DatasetModel): Promise<DatasetModel> {
    const url = `${this.baseUrl}/project/${projectId}/dataset`;
    const options = standardPostOptions({ body: JSON.stringify(data) });
    const response = await this.fetchFn(url, options);
    return (await response.json()) as DatasetModel;
  }

  async createProcessedDataset(projectId: ApiId, parentId: ApiId, name: string | undefined, description: string | undefined, processorId: ApiId, processorParams: Record<string, any>): Promise<DatasetModel> {
    const url = `${this.baseUrl}/project/${projectId}/dataset/${parentId}/process`;
    const options = standardPostOptions({ body: JSON.stringify({name, description, processor_id: processorId, processor_params: processorParams}) });
    const response = await this.fetchFn(url, options);
    return (await response.json()) as DatasetModel;
  }

  async updateDataset(projectId: ApiId, datasetId: number, name?: string, description?: string): Promise<DatasetModel> {
    const url = `${this.baseUrl}/project/${projectId}/dataset/${datasetId}`;
    const options = standardPutOptions({ body: JSON.stringify({name, description}) });
    const response = await this.fetchFn(url, options);
    return (await response.json()) as DatasetModel;      
  }

  async deleteDataset(projectId: ApiId, datasetId: number): Promise<void> {
    const url = `${this.baseUrl}/project/${projectId}/dataset/${datasetId}`;
    const options = standardDeleteOptions();
    const response = await this.fetchFn(url, options);
    if (!response.ok) {
      throwApiError(response.status);
    }
    return;
  }

  async uploadDatasetContent(
    projectId: ApiId,
    datasetId: ApiId,
    filename: string,
    data: ArrayBuffer
  ): Promise<Response> {
    const url = `${this.baseUrl}/project/${projectId}/dataset/${datasetId}/data/${filename}`;
    const options = standardPostOptions({ body: data });
    (options.headers! as any)["Content-Type"] = "application/octet-stream";
    const response = await this.fetchFn(url, options);
    if (!response.ok && response.status === 400) {
      const details = await response.json();
      throwApiError(response.status, details);
    } else {
      throwApiError(response.status);
    }
    return response;
  }

  datasetDownloadUrl(projectId: ApiId, datasetId: ApiId): string {
    return `${this.baseUrl}/project/${projectId}/dataset/${datasetId}/data`;
  }

  async fetchJobs(projectId: ApiId, completed: boolean, pending: boolean, relatedEntity?: string, relatedIds?: ApiId[], jobIds?: ApiId[]): Promise<JobInfoModel[]> {
    const url = `${this.baseUrl}/project/${projectId}/job`;
    const u = new URL(url);
    u.searchParams.set('completed', boolStr(completed))
    u.searchParams.set('pending', boolStr(pending))
    if (relatedEntity) {
      u.searchParams.set('related_entity', relatedEntity)
    }
    if (relatedIds) {
      u.searchParams.set('related_ids', relatedIds.join(","))
    }
    if (jobIds) {
      u.searchParams.set('job_ids', jobIds.join(","))
    }
    const response = await this.fetchFn(u.toString(), standardGetOptions());
    const data = await response.json();
    if (!response.ok) {
      throwApiError(response.status);
    }
    return data as JobInfoModel[];
  }

  async fetchJob(projectId: ApiId, jobId: ApiId): Promise<JobInfoModel> {
    const url = `${this.baseUrl}/project/${projectId}/job/${jobId}`;
    const response = await this.fetchFn(url, standardGetOptions());
    const data = await response.json();
    if (!response.ok) {
      throwApiError(response.status);
    }
    return data as JobInfoModel;
  }

  async fetchProcessor(projectId: ApiId, processorId: ApiId): Promise<ProcessorModel> {
    const url = `${this.baseUrl}/project/${projectId}/processor/${processorId}`;
    const response = await this.fetchFn(url, standardGetOptions());
    const data = await response.json();
    if (!response.ok) {
      throwApiError(response.status);
    }
    return data as ProcessorModel;
  }

  async fetchProcessors(projectId: ApiId): Promise<ApiBatchResult<ProcessorModel>> {
    const url = `${this.baseUrl}/project/${projectId}/processor`;
    const response = await this.fetchFn(url, standardGetOptions());
    const data = await response.json();
    if (!response.ok) {
      throwApiError(response.status);
    }
    const items = data as ProcessorModel[];
    return {
      empty: items.length === 0,
      count: items.length,
      items,
    };
  }

  async createProcessor(projectId: ApiId, data: ProcessorModel): Promise<ProcessorModel> {
    const url = `${this.baseUrl}/project/${projectId}/processor`;
    const options = standardPostOptions({ body: JSON.stringify(data) });
    const response = await this.fetchFn(url, options);
    if (!response.ok) {
      throwApiError(response.status);
    }
    return (await response.json()) as ProcessorModel;
  }

  async deleteProcessor(projectId: ApiId, processorId: number): Promise<void> {
    const url = `${this.baseUrl}/project/${projectId}/processor/${processorId}`;
    const options = standardDeleteOptions();
    const response = await this.fetchFn(url, options);
    if (!response.ok) {
      throwApiError(response.status);
    }
    return;
  }

  async uploadProcessorContent(
    projectId: ApiId,
    processorId: ApiId,
    filename: string,
    data: ArrayBuffer
  ): Promise<Response> {
    const url = `${this.baseUrl}/project/${projectId}/processor/${processorId}/data/${filename}`;
    const options = standardPostOptions({ body: data });
    (options.headers! as any)["Content-Type"] = "application/octet-stream";
    const response = await this.fetchFn(url, options);
    if (!response.ok) {
      throwApiError(response.status, await response.json() ?? {});
    }
    return response;
  }

  processorDownloadUrl(projectId: ApiId, processorId: ApiId): string {
    return `${this.baseUrl}/project/${projectId}/processor/${processorId}/data`;
  }

  // ---- EXPLORATIONS -----

  async fetchExploration(projectId: ApiId, explorationId: ApiId): Promise<ExplorationModel> {
    const url = `${this.baseUrl}/project/${projectId}/exploration/${explorationId}`;
    const response = await this.fetchFn(url, standardGetOptions());
    const data = await response.json();
    if (!response.ok) {
      throwApiError(response.status);
    }
    return data as ExplorationModel;

  }
  async fetchExplorations(projectId: ApiId): Promise<ApiBatchResult<ExplorationModel>> {
    const url = `${this.baseUrl}/project/${projectId}/exploration`;
    const response = await this.fetchFn(url, standardGetOptions());
    const data = await response.json();
    if (!response.ok) {
      throwApiError(response.status);
    }
    const items = data as ExplorationModel[];
    return {
      empty: items.length === 0,
      count: items.length,
      items,
    };
  }

  async createExploration(projectId: ApiId, data: ExplorationModel): Promise<ExplorationModel> {
    const url = `${this.baseUrl}/project/${projectId}/exploration`;
    const options = standardPostOptions({ body: JSON.stringify(data) });
    const response = await this.fetchFn(url, options);
    if (!response.ok) {
      throwApiError(response.status);
    }
    return (await response.json()) as ExplorationModel;
  }

  async updateExploration(projectId: ApiId, explorationId: ApiId, name?: string, description?: string): Promise<ExplorationModel> {
    const url = `${this.baseUrl}/project/${projectId}/exploration/${explorationId}`;
    const options = standardPutOptions({ body: JSON.stringify({name, description }) });
    const response = await this.fetchFn(url, options);
    if (!response.ok) {
      throwApiError(response.status);
    }
    return (await response.json()) as ExplorationModel;
  }

  async deleteExploration(projectId: ApiId, explorationId: ApiId): Promise<ExplorationModel> {
    const url = `${this.baseUrl}/project/${projectId}/exploration/${explorationId}`;
    const options = standardDeleteOptions();
    const response = await this.fetchFn(url, options);
    if (!response.ok) {
      throwApiError(response.status);
    }
    return (await response.json()) as ExplorationModel;
  }

  async createExplorationSelection(projectId: ApiId, explorationId: ApiId, selection: ExplorationSelection): Promise<ExplorationSelection> {
    const url = `${this.baseUrl}/project/${projectId}/exploration/${explorationId}/selections`;
    const options = standardPostOptions({ body: JSON.stringify(selection) });
    const response = await this.fetchFn(url, options);
    if (!response.ok) {
      throwApiError(response.status);
    }
    return (await response.json()) as ExplorationSelection;
  }

  async saveExplorationSelection(projectId: ApiId, explorationId: ApiId, selectionId: ApiId, selection: ExplorationSelection): Promise<ExplorationSelection> {
    const url = `${this.baseUrl}/project/${projectId}/exploration/${explorationId}/selections/${selectionId}`;
    const options = standardPutOptions({ body: JSON.stringify({...selection}) });
    const response = await this.fetchFn(url, options);
    if (!response.ok) {
      throwApiError(response.status);
    }
    return (await response.json()) as ExplorationSelection;
  }

  async deleteExplorationSelection(projectId: ApiId, explorationId: ApiId, selectionId: ApiId): Promise<void> {
    const url = `${this.baseUrl}/project/${projectId}/exploration/${explorationId}/selections/${selectionId}`;
    const options = standardDeleteOptions();
    const response = await this.fetchFn(url, options);
    if (!response.ok) {
      throwApiError(response.status);
    }
    return;
  }

  // ---- PROMPT ----

  async fetchPrompts(projectId: ApiId, type?: string): Promise<ApiBatchResult<PromptModel>> {
    const url = `${this.baseUrl}/project/${projectId}/prompt` + (type ? `?type=${type}` : '');
    const response = await this.fetchFn(url, standardGetOptions());
    const data = await response.json();
    if (!response.ok) {
      throwApiError(response.status);
    }
    const items = data as PromptModel[];
    return {
      empty: items.length === 0,
      count: items.length,
      items,
    };
  }

  async fetchPrompt(projectId: ApiId, promptId: ApiId): Promise<PromptModel> {
    const url = `${this.baseUrl}/project/${projectId}/prompt/${promptId}`;
    const response = await this.fetchFn(url, standardGetOptions());
    const data = await response.json();
    if (!response.ok) {
      throwApiError(response.status);
    }
    return data as PromptModel;
  }

  async createPrompt(projectId: ApiId, data: PromptModel): Promise<PromptModel> {
    const url = `${this.baseUrl}/project/${projectId}/prompt`;
    const options = standardPostOptions({ body: JSON.stringify(data) });
    const response = await this.fetchFn(url, options);
    if (!response.ok) {
      throwApiError(response.status);
    }
    return (await response.json()) as PromptModel;
  }

  async updatePrompt(projectId: ApiId, promptId: ApiId, data: PromptModel): Promise<PromptModel> {
    const url = `${this.baseUrl}/project/${projectId}/prompt/${promptId}`;
    const options = standardPutOptions({ body: JSON.stringify(data) });
    const response = await this.fetchFn(url, options);
    if (!response.ok) {
      throwApiError(response.status);
    }
    return (await response.json()) as PromptModel;
  }

  async deletePrompt(projectId: ApiId, promptId: number): Promise<void> {
    const url = `${this.baseUrl}/project/${projectId}/prompt/${promptId}`;
    const options = standardDeleteOptions();
    const response = await this.fetchFn(url, options);
    if (!response.ok) {
      throwApiError(response.status);
    }
    return;
  }
}