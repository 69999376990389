import AddButtonIcon from "@mui/icons-material/Add";
import CheckBoxCheckedIcon from '@mui/icons-material/CheckBox';
import CheckBoxBlankIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import RemoveButtonIcon from '@mui/icons-material/HighlightOff';
import {
  Autocomplete,
  AutocompleteChangeReason,
  Box,
  Button,
  Divider,
  FormControl,
  IconButton,
  Stack,
  styled,
  SxProps,
  TextField,
  Theme,
  Typography,
  useTheme
} from "@mui/material";
import { FC, SyntheticEvent, useState } from "react";
import {
  emptyExplorationFilterClause,
  emptyExplorationTopicFilter,
  ExplorationFilter,
  ExplorationFilterClause,
  ExplorationSampleFilter,
  ExplorationTopicFilter,
} from "../../../api/apimodels";
import { Tag } from "../../../components/common/Tag";
import { StableColorMap } from "../../../util/colormap";

type OperatorType = { id: string; text: string };

export const Operator: Record<string, OperatorType> = {
    ANY_OF: { id: "or", text: "any of" },
    ALL_OF: { id: "and", text: "all of" },
    NONE_OF: { id: "not", text: "none of" },
};

type OperationButtonProps = {
    op: OperatorType;
    onChange: (nextOperator: OperatorType) => void;
    operators?: OperatorType[];
};

function getBypassSx(enabled: boolean): SxProps<Theme> {
    return {opacity: enabled ? 1.0 : 0.25 };
}

const OrderedOperators = [Operator.ANY_OF, Operator.ALL_OF, Operator.NONE_OF];

const OperatorButton: FC<OperationButtonProps> = ({ op, onChange, operators = OrderedOperators }) => {
    function onClick() {
        const idx = (operators.findIndex((o) => o.id === op.id) + 1) % operators.length;
        onChange(operators[idx]);
    }

    return (
        <Button variant="text" onClick={onClick} sx={{whiteSpace: 'pre'}}>
            {op.text}
        </Button>
    );
}

type TopicFilterProps = {
    filter: ExplorationTopicFilter | undefined;
    topicIds: string[];
    onChange: (newFilter: ExplorationTopicFilter) => void;
};

const sectionLabelSx: SxProps<Theme> = {
    fontSize: '105%',
    fontWeight: 300
};

export const FilterClauseLabel = styled(Typography)(({ theme }) => ({
    fontSize: theme.typography.fontSize,
    color: theme.palette.text.primary
}));

const FilterHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  width: '100%'
}));

const MiniCheckbox: FC<{checked: boolean, onChange: (checked: boolean) => void, sx?: SxProps<Theme>}> = ({checked, onChange, sx}) => {
  const onClick = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onChange(!checked);
  };
  return (
    <Box onClick={onClick} sx={{lineHeight: '100%', ...sx}}>
      {checked ? (
        <CheckBoxCheckedIcon fontSize={"inherit"} sx={{ mr: 1 }} />
      ) : (
        <CheckBoxBlankIcon fontSize={"inherit"} sx={{ mr: 1 }} />
      )}
    </Box>
  );
}

function TopicFilter(props: TopicFilterProps) {
    const theme = useTheme();
    const { filter, onChange, topicIds } = props;
    const [op, setOp] = useState<OperatorType>(
        OrderedOperators.find((o) => o.id === filter?.operator) ?? Operator.ANY_OF
    );

    const onEnableChange = () => {
      const newFilter: ExplorationTopicFilter = filter ? { ...filter } : { ...emptyExplorationTopicFilter };
      newFilter.enabled = !newFilter.enabled;
      onChange(newFilter as ExplorationTopicFilter);
    }

    const onAddClause = () => {
        const newFilter: ExplorationTopicFilter = filter ? { ...filter } : { ...emptyExplorationTopicFilter };
        const clauses = [...newFilter.clauses, { ids: [], enabled: true, operator: Operator.ANY_OF.id }];
        newFilter.clauses = clauses;
        onChange(newFilter);
    };

    const onChangeClause = (index: number, clause: ExplorationFilterClause) => {
        const newFilter: ExplorationTopicFilter = filter ? { ...filter } : { ...emptyExplorationTopicFilter };
        const newClauses = [...newFilter.clauses];
        newClauses[index] = clause;
        newFilter.clauses = newClauses;
        onChange(newFilter);
    }

    const onOperatorChange = (newOperator: OperatorType) => {
        const newFilter: ExplorationTopicFilter = filter ? { ...filter } : { ...emptyExplorationTopicFilter };
        newFilter.operator = newOperator.id;
        setOp(newOperator);
        onChange(newFilter);
    }

    const onRemoveClause = (index: number) => {
        const newFilter: ExplorationTopicFilter = filter ? { ...filter } : { ...emptyExplorationTopicFilter };
        const newClauses = [...newFilter.clauses];
        newClauses.splice(index, 1);
        newFilter.clauses = newClauses;
        onChange(newFilter);
    }

    const bypassSx = getBypassSx(!!filter?.enabled);
    const filterClauseBackgroundColor = theme.palette.mode === "light" ? theme.palette.grey[100]: theme.palette.grey[900];

    return (
        <Box>
            <FormControl sx={{ width: "100%" }}>
                <Stack direction="column" alignItems="center">
                    <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: '100%', mb: 1 }}>
                        <FilterHeader id="filter-panel-theme-label">
                            <MiniCheckbox checked={!!filter?.enabled} onChange={onEnableChange} sx={{fontSize: '18px' }} />
                            <FilterClauseLabel variant="body1" sx={{...sectionLabelSx, ...bypassSx} as SxProps<Theme>}>
                              Topic Filter
                            </FilterClauseLabel>
                        </FilterHeader>
                        <OperatorButton op={op} onChange={onOperatorChange} />
                    </Stack>
                    {(filter?.clauses ?? []).map((clause, i) => {
                        return (
                            <Box key={`_${i}`} sx={{ width: '100%', backgroundColor: filterClauseBackgroundColor, px: 1, py: 0, borderRadius: 1, mb: 1, ...bypassSx }} >
                                <FilterClause
                                    filter={clause}
                                    label="Topics:"
                                    addLabel={"Add topic"}
                                    options={topicIds}
                                    onChange={(clause: ExplorationFilterClause) => onChangeClause(i, clause)}
                                    onRemove={() => { onRemoveClause(i); }}
                                />
                            </Box>
                        );
                    })}
                    <Box><IconButton onClick={onAddClause} color="primary"><AddButtonIcon /></IconButton></Box>
                </Stack>
            </FormControl>
        </Box>
    );
}

type FilterClauseProps = {
    filter: ExplorationFilterClause | undefined;
    label?: string;
    standalone?: boolean;
    options: string[];
    addLabel: string;
    onChange: (newFilter: ExplorationFilterClause) => void;
    onRemove?: () => void;
    validOperators?: OperatorType[];
};

const FilterClause: FC<FilterClauseProps> = ({ filter, label, addLabel, onChange, options, standalone, onRemove, validOperators = OrderedOperators }) => {
    const [rerenderTrigger, setRerenderTrigger] = useState(Date.now());
    const [op, setOp] = useState<OperatorType>(
      validOperators.find((o) => o.id === filter?.operator) ?? Operator.ANY_OF
    );

    function onOperatorChange(newOperator: OperatorType) {
      const newFilter: ExplorationFilterClause = filter ? { ...filter } : { ...emptyExplorationFilterClause };
      newFilter.operator = newOperator.id;
      setOp(newOperator);
      onChange(newFilter);
    }

    function onEnableChange() {
      const newFilter = { ...{ enabled: !!filter?.enabled, ids: [], operator: Operator.ANY_OF.id }, ...filter };
      newFilter.enabled = !newFilter.enabled;
      onChange(newFilter as ExplorationFilterClause);
    }

    function onAutocompleteChange(e: SyntheticEvent<Element>, value: string | null, reason: AutocompleteChangeReason) {
      // TODO: Ensure that value is on list
      if (value) {
        const newFilter: ExplorationFilterClause = filter ? { ...filter } : { ...emptyExplorationFilterClause };
        const newIds = new Set([...newFilter.ids]);
        newIds.add(value);
        newFilter.ids = Array.from(newIds);
        onChange(newFilter);
        setRerenderTrigger(rerenderTrigger + 1);
      }
    }

    function onRemoveItem(id: string) {
        const newFilter: ExplorationFilterClause = filter ? { ...filter } : { ...emptyExplorationFilterClause };
        const newIds = new Set([...newFilter.ids]);
        newIds.delete(id);
        newFilter.ids = Array.from(newIds);
        onChange(newFilter);
    }

    const renderAutocomplete = () => {
        return <Autocomplete
            options={options}
            sx={{ mt: 1, minWidth: '100%' }}
            onChange={onAutocompleteChange}
            renderInput={(params) => <TextField {...params} label={addLabel} variant="outlined" size="small" />}
        />;
    }

    const onRemoveClause = (e: any) => {
      e.preventDefault();
      e.stopPropagation(); 
      if (onRemove) {
        onRemove();
      }
    }
    
    const bypassSx = getBypassSx(!!filter?.enabled);

    return (
        <Box sx={{ mt: 1 }}>
            <FormControl sx={{ width: "100%" }}>
                <Stack direction="column" alignItems="center">
                    {label ? (
                        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: "100%" }}>
                            {label && (
                                <FilterHeader id="filter-panel-topic-label" sx={bypassSx}>
                                    <MiniCheckbox checked={!!filter?.enabled} onChange={onEnableChange} />
                                    <FilterClauseLabel variant="body1" sx={standalone ? sectionLabelSx : {}}>
                                        {label}
                                        {!standalone && (
                                            <IconButton size="small" color="primary" onClick={onRemoveClause} sx={{ml: 0.25}}>
                                                <RemoveButtonIcon fontSize="inherit" sx={{ position: 'relative', top: -0.5 }} />
                                            </IconButton>)}
                                        
                                    </FilterClauseLabel>
                                </FilterHeader>)}
                            <OperatorButton op={op} onChange={onOperatorChange} operators={validOperators} />
                        </Stack>
                    ) : (
                        <OperatorButton op={op} onChange={onOperatorChange} operators={validOperators} />
                    )}
                    <Box alignSelf="flex-start" sx={{ mt: 0, ...bypassSx}}>
                        {filter?.ids.map((id) => (
                            <Tag key={`id_${id}`} label={id.replaceAll("-", " ")} title={id.replaceAll("-", " ")} onDelete={() => { onRemoveItem(id); }} sx={{ m: 0.25, textTransform: 'capitalize' }} />
                        ))}
                    </Box>
                    <Box sx={{ width: '100%', mt: 0, mb: 1, ...bypassSx }}>
                        {rerenderTrigger % 2 === 0 && (renderAutocomplete())}
                        {rerenderTrigger % 2 === 1 && (renderAutocomplete())}
                    </Box>
                </Stack>
            </FormControl>
        </Box>
    );
}

type FilterSelectedProps = {
    filter?: ExplorationSampleFilter;
    selectedIds?: string[];
    onChange: (sampleFilter: ExplorationSampleFilter) => void;
};

const FilterSelected: FC<FilterSelectedProps> = ({ filter, onChange, selectedIds = [] }) => {
    function onEnableChange() {
        const newFilter = {...{ enabled: !!filter?.enabled, ids: [], operator: Operator.ALL_OF.id }, ...filter};
        newFilter.enabled = !newFilter.enabled;
        onChange(newFilter as ExplorationSampleFilter);
    }
    
    const bypassSx = getBypassSx(!!filter?.enabled);

    return (
        <Box>
        <FormControl sx={{ width: "100%" }}>
            <Stack direction="column" alignItems="center">
                <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: "100%" }}>
                    <FilterHeader id="filter-panel-selected-label" sx={{...bypassSx}}>
                      <MiniCheckbox checked={!!filter?.enabled} onChange={onEnableChange} />
                      <FilterClauseLabel variant="body1" sx={sectionLabelSx}>Selected rows</FilterClauseLabel>
                    </FilterHeader>
                </Stack>
            </Stack>
        </FormControl>
        </Box>
    )
}

type Props = {
    filter?: ExplorationFilter;
    sx?: SxProps<Theme>;
    themeIds?: string[];
    topicIds?: string[];
    showThemes?: boolean;
    showTopics?: boolean;
    selectedIds: string[];
    themeColorFn: StableColorMap;
    onChange: (newFilter: ExplorationFilter, clearSelectedIds: boolean) => void;
};

const FilterPanel: FC<Props> = ({ sx, onChange, themeIds, topicIds, filter, selectedIds }) => {
    const showTopics = topicIds && topicIds.length > 0;
    const showTheme = themeIds && themeIds.length > 0;

    function onTopicFilterChange(topicFilter: ExplorationTopicFilter) {
        const newFilter = { ...filter };
        newFilter.topic = topicFilter;
        newFilter.sample = undefined;
        onChange(newFilter, true);
    }

    function onThemeFilterChange(themeFilter: ExplorationFilterClause) {
        const newFilter = { ...filter };
        newFilter.theme = themeFilter;
        newFilter.sample = undefined;
        onChange(newFilter, true);
    }

    function onSelectedChange(sampleFilter: ExplorationSampleFilter) {
        const newFilter = { ...filter };
        newFilter.sample = sampleFilter;
        onChange(newFilter, false);
    }

    return (
        <Box sx={{ ...sx }}>
            <Stack direction="column">
                {showTopics && (
                    <TopicFilter filter={filter?.topic} onChange={onTopicFilterChange} topicIds={topicIds} />
                )}
                {showTopics && showTheme && (
                    <Divider sx={{ mt: 1, mb: 1 }} />
                )}
                {showTheme && (
                    <FilterClause filter={filter?.theme} options={themeIds} onChange={onThemeFilterChange} label="Theme Filter" addLabel="Add theme" standalone validOperators={[Operator.ANY_OF, Operator.NONE_OF]} />
                )}
                {(showTopics || showTheme) && (
                    <Divider sx={{ mt: 3, mb: 2 }} />)
                }
                <FilterSelected filter={filter?.sample} onChange={onSelectedChange} selectedIds={selectedIds} />
            </Stack>
        </Box>
    );
}
export default FilterPanel;
