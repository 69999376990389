import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";

type EnumDropdownProps = {
  id: string;
  className?: string;
  options: string[];
  value: string;
  label: string;
  disabled?: boolean;
  onChange: (id: string, value: string) => void;
};

export function EnumDropdown(props: EnumDropdownProps) {
  const { id, className, options, label, value, onChange, disabled } = props;
  return (
    <Box className={className} sx={{ width: "100%" }}>
      <FormControl variant="filled" sx={{ width: "100%" }}>
        <InputLabel id={id + "-label"}>{label}</InputLabel>
        <Select
          labelId={id + "-label"}
          id={id}
          value={value}
          disabled={disabled}
          onChange={(e) => onChange(id, e.target.value as string)}
          label={label}
        >
          {options.map((o) => (
            <MenuItem key={o} value={o}>
            {o}
          </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
