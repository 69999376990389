import { ApiId, LangTextAggregateOptions, LangTextSummaryAvailableOptions, LangTextSummaryStatistics, LangTextSummmary } from "../apimodels";
import { standardGetOptions, standardPostOptions, throwApiError } from "../helpers";
import { FetchFn, ILangApi } from "../types";

export class LangApi implements ILangApi {
  private baseUrl: string;
  private fetchFn: FetchFn;
  public ver: number;

  constructor(baseUrl: string, fetchFn: FetchFn | undefined) {
    this.baseUrl = baseUrl;
    this.fetchFn = fetchFn ?? ((input, init) => fetch(input, init));
    this.ver = Math.random() * 10000000;
  }

  async summaryOptions(): Promise<LangTextSummaryAvailableOptions> {
    const url = `${this.baseUrl}/summary/options`;
    const response = await this.fetchFn(url, standardGetOptions());
    const data = await response.json();
    return data as LangTextSummaryAvailableOptions;
  }

  async querySummaryStatistics(projectId: ApiId, datasetId: ApiId, filename: string, ids: string[], options: LangTextAggregateOptions, numOutputTokens: number): Promise<LangTextSummaryStatistics> {
    const url = `${this.baseUrl}/summary/statistics?project_id=${projectId}&dataset_id=${datasetId}&filename=${filename}&num_output_tokens=${numOutputTokens}`;
    const response = await this.fetchFn(url, standardPostOptions({ body: JSON.stringify({ ids, options }) }));
    const data = await response.json();
    return data as LangTextSummaryStatistics;
  }

  async generateSummary(projectId: ApiId, datasetId: ApiId, filename: string, ids: string[], options: LangTextAggregateOptions): Promise<LangTextSummmary> {
    const url = `${this.baseUrl}/summary?project_id=${projectId}&dataset_id=${datasetId}&filename=${filename}`;
    const response = await this.fetchFn(url, standardPostOptions({ body: JSON.stringify({ ids, options }) }));
    if (!response.ok) {
      if (response.status === 400) {
        const data = await response.json();
        throwApiError(response.status, data as Record<string, any>);
      }
      throwApiError(response.status);
    }
    const data = await response.json();
    return data as LangTextSummmary;
  }
}