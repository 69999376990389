import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormGroup, Switch, TextField } from "@mui/material";
import { ReactNode, useEffect, useState } from "react";

type CreateOrEditDialogProps = {
    open: boolean;
    dialogTitle: string;
    submitButtonTitle: string;
    name?: string;
    description?: string;
    onClose: () => void;
    onSubmit: (name: string, description: string, keepFilter: boolean) => void;
}

export function CreateOrEditDialog(props: CreateOrEditDialogProps) {
    const { open, onClose, onSubmit, submitButtonTitle: createButtonTitle, dialogTitle, name, description } = props;
    const [selectionName, setSelectionName] = useState(name ?? "");
    const [selectionDescription, setSelectionDescription] = useState(description ?? "");
    const [keepFilter, setKeepFilter] = useState(false);

    const onCloseDialog = () => {
        setSelectionName("");
        setSelectionDescription("");
        setKeepFilter(false);
        onClose();
    }

    return (
        <Dialog
            open={open}
            onClose={onCloseDialog}
            PaperProps={{
                component: 'form',
                onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                    event.preventDefault();
                    onSubmit(selectionName, selectionDescription, keepFilter);
                    setSelectionName("");
                    setSelectionDescription("");
                    setKeepFilter(false);      
                },
            }}
        >
            <DialogTitle>{dialogTitle}</DialogTitle>
            <DialogContent>
                <TextField autoFocus required margin="dense" id="name" name="name" label="Name" type="text" fullWidth value={selectionName} onChange={(e) => { setSelectionName(e.target.value); }} variant="standard" />
                <TextField multiline margin="dense" id="description" name="description" label="Description" type="text" value={selectionDescription} onChange={(e) => { setSelectionDescription(e.target.value); }} fullWidth variant="standard" />
            </DialogContent>
            <DialogActions>
                <FormGroup sx={{flexGrow: 1, pl: 2}}>
                  <FormControlLabel control={<Switch value={keepFilter} onChange={() => setKeepFilter(!keepFilter)}/>} label="Keep filter" sx={{ "& .MuiFormControlLabel-label": { opacity: 0.85, fontSize: '90%' }}}/>
                </FormGroup>
                <Button onClick={onCloseDialog}>Cancel</Button>
                <Button type="submit" disabled={selectionName.trim().length === 0}>{createButtonTitle}</Button>
            </DialogActions>
        </Dialog>
    );
}

type DetailsDialogProps = {
  open: boolean;
  dialogTitle: string;
  name?: string;
  description?: string;
  onClose: () => void;
  onDelete: () => void;
  onSave: (name: string, description: string) => void;
}

export function DetailsDialog(props: DetailsDialogProps) {
  const { open, onClose, onSave, onDelete, dialogTitle, name, description } = props;
  const [selectionName, setSelectionName] = useState(name ?? "");
  const [selectionDescription, setSelectionDescription] = useState(description ?? "");
  
  useEffect(() => {
    if (open) {
      setSelectionName(name ?? "");
      setSelectionDescription(description ?? "");
    } else {
      setSelectionName("");
      setSelectionName("");
    }
  }, [open, name, description]);

  return (
      <Dialog
          open={open}
          onClose={onClose}
          PaperProps={{
              component: 'form',
              onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                  event.preventDefault();
                  onSave(selectionName, selectionDescription);
              },
          }}
      >
          <DialogTitle>{dialogTitle}</DialogTitle>
          <DialogContent>
              <TextField required margin="dense" id="name" name="name" label="Name" type="text" fullWidth value={selectionName} onChange={(e) => { setSelectionName(e.target.value); }} variant="standard" />
              <TextField multiline margin="dense" id="description" name="description" label="Description" type="text" value={selectionDescription} onChange={(e) => { setSelectionDescription(e.target.value); }} fullWidth variant="standard" />
          </DialogContent>
          <DialogActions>
              <Box sx={{flexGrow: 1, ml: 1}}>
                <Button onClick={onDelete} color="error">Delete</Button>
              </Box>
              <Button onClick={onClose}>Cancel</Button>
              <Button type="submit">Save</Button>
          </DialogActions>
      </Dialog>
  );
}

type DeleteSelectionDialogProps = {
    open: boolean;
    dialogTitle: string;
    deleteButtonTitle: string;
    children: ReactNode;
    onClose: () => void;
    onSubmit: () => void;
}

export function DeleteSelectionDialog(props: DeleteSelectionDialogProps) {
    const { open, onClose, onSubmit, dialogTitle, deleteButtonTitle, children } = props;
    return (<Dialog
        open={open}
        onClose={onClose}
        PaperProps={{
            component: 'form',
            onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                event.preventDefault();
                onSubmit();
            },
        }}
    >
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
            {children}
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <Button type="submit" color="error">{deleteButtonTitle}</Button>
        </DialogActions>
    </Dialog>)
}