import { ReactNode, useCallback, useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Pagination from '@mui/material/Pagination';
import { Button, Box, Checkbox, Stack, SxProps, Theme, Typography, styled, Select, FormControl, InputLabel, MenuItem, Tooltip } from "@mui/material";
import { cleanupFilter, DataSamplesResult, DatasetModel, ExplorationFilter } from "../../../api/apimodels";
import { Apis } from "../../../api/apis";
import { CustomChipTooltip, Tag } from "../../../components/common/Tag";
import { StableColorMap } from "../../../util/colormap";
import { ColumnId } from "../../../api/data";
import { AnyDataStateAction, DataState, DataStateActionTypes } from "./DataState";

const tableMinWidth = 'auto';

type Column = {
    id: string;
    name?: string;
    width: string;
    headerAlign?: string;
    maxChars?: number;
};

const selectionColumn: Column = { id: 'selected', name: '', width: '2.5em' }

const standardColumns: Column[] = [
    selectionColumn,
    { id: ColumnId.TEXT, name: "Text", width: "", maxChars: 228 },
    { id: ColumnId.TOPICS, name: "Topics", width: "" },
    { id: ColumnId.THEME, name: "Theme", width: "5em" },
];

const ReadMoreButton = styled(Button)(({ theme }) => ({
    display: "inline",
    lineHeight: theme.spacing(0),
    height: "auto",
    fontSize: theme.typography.caption.fontSize,
    minWidth: "0",
    "& .MuiButton-startIcon": {
        marginLeft: 0,
        marginRight: 0,
    },
}));

const CellTh = styled(TableCell)(({ theme }) => ({
    ...theme.typography.body1,
    fontSize: theme.typography.fontSize,
    textTransform: 'capitalize',
    fontWeight: 600,
    "&:first-of-type": {
        paddingLeft: theme.spacing(0.5)
    },
    "&:last-of-type": {
        paddingRight: theme.spacing(0.5)
    }
}));

const CellTd = styled(TableCell)(({ theme }) => ({
    ...theme.typography.body1,
    fontSize: theme.typography.fontSize,
    fontWeight: 100,
    height: theme.spacing(11),
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1),
    "&:first-of-type": {
        paddingLeft: theme.spacing(0.5)
    },
    "&:last-of-type": {
        paddingRight: theme.spacing(0.5)
    }
}));

const PageSizeSelect = styled(Select)(({ theme }) => ({
    fontSize: theme.typography.fontSize,
    paddingTop: theme.spacing(0.125),
    paddingBottom: theme.spacing(0.125)
}));

const PageSizeMenuItem = styled(MenuItem)(({ theme }) => ({
    fontSize: theme.typography.fontSize,
}));

const CircularChip = styled(Box)(({ theme }) => ({
    minWidth: theme.spacing(2.5),
    minHeight: theme.spacing(2.5),
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    borderRadius: theme.spacing(2.5)
}));

type Row = Record<string, any>;

const standardColumnMap = (() => {
    const m: Record<string, Column> = {};
    standardColumns.forEach((o) => (m[o.id] = o));
    return m;
})();

type DataPaginationProps = {
    page: number;
    pageSize: number;
    totalSize: number;
    onChange: (pageNumber: number, pageSize: number) => void;
    sx?: SxProps<Theme>;
}

function DataPagination(props: DataPaginationProps) {
    const { page, pageSize, totalSize, onChange, sx } = props;
    const defaultPageSize = 10;
    const numPages = Math.floor((totalSize - 1) / pageSize);
    return <Stack direction="row" sx={{ ...sx, width: '100%', alignItems: 'center' }}>
        <Pagination page={page} count={numPages} onChange={(e, pageNumber) => onChange(pageNumber, pageSize)} sx={{ flexGrow: 1 }} />
        <Box sx={{ marginLeft: 'auto' }}>
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small" >
                <InputLabel id="page-size-select">Page size</InputLabel>
                <PageSizeSelect
                    labelId="page-size-select"
                    value={pageSize}
                    label="Page size"
                    onChange={(e) => onChange(page, (e.target.value ?? defaultPageSize) as number)}
                >
                    <PageSizeMenuItem value={10}>10</PageSizeMenuItem>
                    <PageSizeMenuItem value={25}>25</PageSizeMenuItem>
                    <PageSizeMenuItem value={50}>50</PageSizeMenuItem>
                    <PageSizeMenuItem value={100}>100</PageSizeMenuItem>
                </PageSizeSelect>
            </FormControl>
        </Box>
    </Stack>
}

function renderHeaderCell(c: Column, onClickHeaderCell: (columnId: string) => void) {
    switch (c.id) {
        case selectionColumn.id:
            return <Checkbox onClick={(e) => { e.preventDefault(); (e.target as any).checked = false; onClickHeaderCell(selectionColumn.id); }} size="small" />
        default:
            return c.name;
    }
}

function renderCell(r: Row, c: Column, dataState: DataState, themeColorFn: StableColorMap, onReadMore?: (id: string) => void, onSelectRow?: (id: string) => void): ReactNode {
    const text = r[c.id];
    let content: ReactNode = text;
    const sampleId = r[ColumnId.TEXT_ID];
    const cellState = dataState.items[sampleId];

    const onClick = onReadMore ? () => { onReadMore(sampleId) } : undefined;

    const onClickCheckbox = onSelectRow ? () => { onSelectRow(sampleId) } : undefined;

    switch (c.id) {
        case selectionColumn.id:
            content = (<Checkbox checked={!!cellState?.selected} size="small" onChange={onClickCheckbox} />)
            break;
        case ColumnId.TEXT:
            if (!cellState?.expanded) {
                const [isLimit, truncText] = limitChars(text, standardColumnMap[c.id].maxChars);
                content = (
                    <>
                        {isLimit ? truncText + " ...\u00A0 " : <Box sx={{ pb: 0.5 }}>{truncText}</Box>}
                        {isLimit && (
                            <ReadMoreButton
                                startIcon={<ExpandMoreIcon />}
                                size="small"
                                variant="text"
                                onClick={onClick}
                            />
                        )}
                    </>
                );
            } else {
                content = (
                    <>
                        {text}
                        <ReadMoreButton
                            startIcon={<ExpandLessIcon />}
                            size="small"
                            variant="text"
                            onClick={onClick}
                        />
                    </>
                );
            }
            break;
        case ColumnId.TOPICS:
            content = <>
                {(text ?? "")
                  .split(" ")
                  .map((t: string) => t.replaceAll("-", " "))
                  .map((t: string) => <Tag title={t} key={`tag_${t}`} size="small" label={t} sx={{textTransform: 'capitalize'}} />)}
            </>;
            break;
        case ColumnId.THEME:
            //content = <Tag size="small" title={text} label={text} />;
            content = <Stack sx={{ width: '100%' }} direction="row" justifyContent="center">
                <CustomChipTooltip title={text.replaceAll("-", " ")}>
                    <CircularChip sx={{ textTransform: 'capitalize', backgroundColor: themeColorFn(text) }} />
                </CustomChipTooltip>
            </Stack>
            break;
    }
    return content;
}

function limitChars(text: string, maxChars?: number): [boolean, string] {
    if (maxChars && maxChars) {
        if (text.length > maxChars) {
            text = text.substring(0, maxChars);
            return [true, text];
        }
    }
    return [false, text];
}

function tdSx(colId: string): SxProps<Theme> {
    const width = standardColumnMap[colId].width;
    return width ? { width, maxWidth: width } : {};
}

function thSx(colId: string): SxProps<Theme> {
    const width = standardColumnMap[colId].width;
    const headerAlign = standardColumnMap[colId].headerAlign;
    let result: SxProps<Theme> = {};
    if (headerAlign) {
        result = { textAlign: headerAlign }
    }
    if (width) {
        result = { ...result, width, maxWidth: width }
    }
    return result;
}

export type CellState = {
    isExpanded: boolean,
    isSelected: boolean,
};

const addRowId = (r: Row) => ([r, r[ColumnId.TEXT_ID]]);

type DataTableProps = {
    rows: Record<string, any>[];
    idCol: string;
    cols: Column[];
    themeColorFn: StableColorMap;
    dataState: DataState;
    dataStateDispatch: React.Dispatch<AnyDataStateAction>;
};

function DataTable(props: DataTableProps) {
    const { rows, cols, idCol, themeColorFn, dataState, dataStateDispatch } = props;

    const onReadMore = (sampleId: string) => {
        dataStateDispatch({
            type: DataStateActionTypes.setExpanded,
            ids: [sampleId],
            expanded: !dataState.items[sampleId]?.expanded
        });
    };

    const onClickCheckbox = (sampleId: string) => {
        dataStateDispatch({
            type: DataStateActionTypes.setSelected,
            ids: [sampleId],
            selected: !dataState.items[sampleId]?.selected
        });
    };

    // FIXME: REALLY change this messy way of connecting table cell and header clicks. Remove globals and more. 

    const onClickHeader = (columnId: string) => {
        switch (columnId) {
            case selectionColumn.id:
                const ids = rows.map((r) => r[idCol]);
                const selected = !ids.find((id) => (dataState.items[id]?.selected));
                dataStateDispatch({
                    type: DataStateActionTypes.setSelected,
                    ids,
                    selected 
                });
        }
    }

    return (
        <TableContainer sx={{ pr: 0}}>
            <Table size="small" sx={{ minWidth: tableMinWidth }}>
                <TableHead>
                    <TableRow>
                        {cols.map((c) => (
                            <CellTh key={"th_" + c.id} sx={thSx(c.id)}>
                                {renderHeaderCell(c, onClickHeader)}
                            </CellTh>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map(addRowId).map(([r, rowId]) => (
                        <TableRow key={`tr_${rowId}`}>
                            {cols.map((c) => (
                                <CellTd key={`tc_${c.id}`} align="left" sx={tdSx(c.id)}>
                                    {renderCell(r, c, dataState, themeColorFn, onReadMore, onClickCheckbox)}
                                </CellTd>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

type Props = {
    sx?: SxProps<Theme>;
    projectId: number;
    dataset: DatasetModel;
    filter?: ExplorationFilter;
    columns?: ColumnId[];
    themeColorFn: StableColorMap;
    dataState: DataState;
    dataStateDispatch: React.Dispatch<AnyDataStateAction>;
};

export function DataPanel(props: Props) {
    const { sx, filter, projectId, dataset, themeColorFn, columns, dataState, dataStateDispatch } = props;
    const cols = [selectionColumn, ...(columns ? standardColumns.filter((c) => (columns.includes(c.id as ColumnId))) : standardColumns)];
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [queryResult, setQueryResult] = useState<DataSamplesResult>({ start: 0, count: 0, total_count: 0, rows: [] });

    const loadPage = useCallback((pageNo: number, samplesPerPage: number) => {
      async function asyncFn() {
          const start = (pageNo - 1) * samplesPerPage;
          const result = await Apis.shared().data.querySamples(projectId, dataset.id!, dataset.filename!, start, samplesPerPage, cleanupFilter(filter));
          setQueryResult(result);
      }
      asyncFn();
    }, [dataset.filename, dataset.id, filter, projectId]);

    // if this triggers, e.g. due to an updated filter or a changed pageSize

    useEffect(() => {
      loadPage(1, pageSize);
    }, [filter, loadPage, pageSize]);

    const onPaginationChange = (newPage: number, newPageSize: number) => {
        if (page !== newPage) {
            setPage(newPage);
            loadPage(newPage, pageSize);
        } else if (pageSize !== newPageSize) {
            const currentItem = pageSize * (page - 1);
            setPageSize(newPageSize);
            setPage(1 + Math.floor(currentItem / newPageSize));
            // page will be loaded due to useEffect that depends on pageSize
        }
    };
    return (
        <Box sx={sx}>
            <Stack direction="column" spacing={1} sx={{ pb: 1 }}>
                {queryResult.rows.length > 0
                    ? (<Box>
                        <DataTable cols={cols} idCol={ColumnId.TEXT_ID} rows={queryResult.rows} themeColorFn={themeColorFn} dataState={dataState} dataStateDispatch={dataStateDispatch} />
                        <Stack sx={{ pt: 2, mr: 1, ml: -1.5 }} justifyContent="center" direction="row">
                            <DataPagination page={page} pageSize={pageSize} totalSize={queryResult.total_count} onChange={onPaginationChange} />
                        </Stack>
                    </Box>)
                    : (<Typography variant="body1" sx={{pl: 2, pt: 2}}>No matching rows</Typography>)
                }
            </Stack>
        </Box>
    );
}
