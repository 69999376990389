import { ReactNode, createContext, useContext, useReducer } from "react";
import { ProjectModel } from "../api/apimodels";
import { ApiLoadingState } from "../api/types";

type ProjectState = {
  loadingState: ApiLoadingState;
};

export enum ProjectActionTypes {
  setLoading,
  loadingComplete,
}

interface ProjectAction {
  type: ProjectActionTypes;
}

export interface LoadingAction extends ProjectAction {
  type: ProjectActionTypes.setLoading;
  loadingState: ApiLoadingState;
}

export interface LoadingCompleteAction extends ProjectAction {
  type: ProjectActionTypes.loadingComplete;
  project: ProjectModel;
}

type AnyProjectAction =
  | LoadingAction
  | LoadingCompleteAction;

const emptyProjectState: ProjectState = {
  loadingState: ApiLoadingState.notLoading,
};

function reducer(state: ProjectState, action: AnyProjectAction) {
  switch (action.type) {
    case ProjectActionTypes.setLoading:
      return {
        ...state,
        loadingState: action.loadingState,
      };
    case ProjectActionTypes.loadingComplete:
      return {
        ...state,
        loadingState: ApiLoadingState.notLoading,
        project: action.project,
      };
  }
  throw new Error("Unknown action.");
}

export const ProjectContext = createContext(emptyProjectState);
export const ProjectDispatcherContext = createContext((_: AnyProjectAction) => {});


export function useProjectReducer() {
  return useReducer(reducer, emptyProjectState);
}

export function useProject() {
  return useContext(ProjectContext);
}

export function useProjectDispatch() {
  return useContext(ProjectDispatcherContext);
}

export const ProjectContextProvider = ({ children }: { children: ReactNode }) => {
  const [project, projectDispatch] = useProjectReducer();

  return (
    <ProjectContext.Provider value={project}>
      <ProjectDispatcherContext.Provider value={projectDispatch}>{children}</ProjectDispatcherContext.Provider>
    </ProjectContext.Provider>
  );
};