import { ApiId, JobInfoModel, StandardItemModel } from "../api/apimodels";

function timeAgo<T extends StandardItemModel>(item: T): number {
  return (Date.now() - (new Date(item.created_at!)).getTime()) / 1000;
}

export function createJobIdMonitor<T extends JobInfoModel>(
  fetchFn: (ids: ApiId[]) => Promise<T[]>,
  completedFn: (completed: ApiId[]) => void, timeout: number, jobTimeout: number = 60 * 60) {
  const monitorSet = new Set<ApiId>();
  let handle: any = null;

  return (jobIds: ApiId[] | null) => {
    clearTimeout(handle);
    handle = null;
    if (jobIds === null || jobIds.length === 0) {
      return;
    }
    jobIds.forEach((i) => { monitorSet.add(i) });
    if (handle === null) {
      const timeoutFn = () => {
        fetchFn(Array.from(jobIds))
        .catch((error) => {
          console.error(error);
          return [];
        })
        .then((pending: T[]) => {
          const pendingIds = new Set(pending
            .filter((j) => (timeAgo(j) < jobTimeout))
            .map((c) => (c.id!))
          );
          const completedIds = monitorSet.difference(pendingIds);
          if (completedIds.size > 0) {
            completedFn(Array.from(completedIds));
          }
          Array.from(monitorSet).forEach((i) => { monitorSet.delete(i); });
          Array.from(pendingIds).forEach((i) => { monitorSet.add(i); });
          if (monitorSet.size > 0) {
            handle = setTimeout(timeoutFn, timeout);
          } else {
            handle = null;
          }
        });
      }
      handle = setTimeout(timeoutFn, timeout);
    }
  };
}