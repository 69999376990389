import { Stack, SxProps, Theme, Typography } from "@mui/material";
import { PieChart, PieChartSlotProps, mangoFusionPalette } from "@mui/x-charts";
import { FC } from "react";
import { StableColorMap } from "../../../../util/colormap";

export type LabelChartProps = {
  label: string;
  rows: [string, number][];
  labelColorFn: StableColorMap;
  maxChipWidth?: string;
  sx?: SxProps<Theme>;
};

export const LabelChart: FC<LabelChartProps> = ({ label, rows, labelColorFn, maxChipWidth = "20em", sx }) => {
  const slotProps: PieChartSlotProps = {
    legend: {
      hidden: true,
    },
  };
//  const palette: string[] = rows.map(([id, value]) => labelColorFn(id));
  return (
    <Stack sx={sx} direction="column" justifyContent="flex-start" alignItems="center">
      <Typography sx={{textTransform: 'capitalize'}}>{label}</Typography>
      <PieChart
        series={[
          {
            data: rows.map(([id, value]) => ({ id, value, label: id })),
            innerRadius: 40,
            outerRadius: 80,
            paddingAngle: 5,
            cornerRadius: 5,
            cx: 100,
            cy: 112,
          },
        ]}
        colors={mangoFusionPalette}
        width={200}
        height={225}
        slotProps={slotProps}
      />
      {/*}
      <Stack direction="column" justifyContent="flex-start">
        {rows.map(([id, value], i) => (
          <Box key={id} sx={{width: 'fit-content'}}>
            <CustomChip label={id} sx={{ maxWidth: maxChipWidth }} markerColor={labelColorFn(id)} />
          </Box>
        ))}
      </Stack>
      {*/}
    </Stack>
  );
}