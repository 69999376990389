import { ReactNode, createContext, useContext } from "react";
import { useReducer } from "react";
import { ProjectModel } from "../api/apimodels";
import { ApiLoadingState } from "../api/types";

type OrganisationState = {
  loadingState: ApiLoadingState;
  projects: ProjectModel[];
};

export enum OrganisationActionTypes {
  setLoading,
  setProjects,
  loadingComplete,
}

interface OrganisationAction {
  type: OrganisationActionTypes;
}

export interface LoadingAction extends OrganisationAction {
  type: OrganisationActionTypes.setLoading;
  loadingState: ApiLoadingState;
}

export interface LoadingCompleteAction extends OrganisationAction {
  type: OrganisationActionTypes.loadingComplete;
  projects: ProjectModel[];
}

export interface SetProjectsAction extends OrganisationAction {
  type: OrganisationActionTypes.setProjects;
  projects: ProjectModel[];
}

type AnyOrganisationAction = LoadingAction | SetProjectsAction | LoadingCompleteAction;

const emptyOrganisationState: OrganisationState = {
  loadingState: ApiLoadingState.notLoading,
  projects: [],
};

function reducer(state: OrganisationState, action: AnyOrganisationAction) {
  switch (action.type) {
    case OrganisationActionTypes.setLoading:
      return {
        ...state,
        loadingState: action.loadingState,
      };
    case OrganisationActionTypes.setProjects:
      return {
        ...state,
        projects: action.projects,
      };
    case OrganisationActionTypes.loadingComplete:
      return {
        ...state,
        loadingState: ApiLoadingState.notLoading,
        projects: action.projects,
      };
  }
  throw new Error("Unknown action.");
}

export const OrganisationContext = createContext(emptyOrganisationState);
export const OrganisationDispatcherContext = createContext((_: AnyOrganisationAction) => {});

export function useOrganisationReducer() {
  return useReducer(reducer, emptyOrganisationState);
}

export function useOrganisation() {
  return useContext(OrganisationContext);
}

export function useOrganisationDispatch() {
  return useContext(OrganisationDispatcherContext);
}

export const OrganisationContextProvider = ({ children }: { children: ReactNode }) => {
  const [organisation, organisationDispatch] = useOrganisationReducer();
  return (
    <OrganisationContext.Provider value={organisation}>
      <OrganisationDispatcherContext.Provider value={organisationDispatch}>{children}</OrganisationDispatcherContext.Provider>
    </OrganisationContext.Provider>
  );
};
