import { ApiId, DataSamplesResult, DatasetModel, DataStatisticsResult, ExplorationFilter, ExplorationModel, ExplorationSelection, JobInfoModel, LangTextAggregateOptions, LangTextSummaryAvailableOptions, LangTextSummaryStatistics, LangTextSummmary, ProcessorModel, ProjectModel, PromptModel } from "./apimodels";

export type SignedLinkFn = (url: string) => Promise<string>;

export type FetchFn = (input: RequestInfo, init?: RequestInit | undefined) => Promise<Response>;

export class ApiError extends Error { 
  protected _statusCode: number | undefined = undefined;
  protected _details: Record<string, any> | undefined = undefined;

  constructor(statusCode?: number, details?: Record<string, any>) {
    super();
    this._statusCode = statusCode;
    this._details = details;
  }

  public get statusCode() : number | undefined {
    return this._statusCode;
  }

  public get details() : Record<string, any> | undefined {
    return this._details;
  }
}

export class ApiNotAuthorizedError extends ApiError { }

export class ApiGeneralError extends ApiError { }

export class ApiNotFoundError extends ApiError { }

export class ApiNoContentError extends ApiError { }

export enum ApiLoadingState {
  notLoading,
  loading,
  error,
}

export interface ApiBatchResult<T> {
  empty: boolean;
  count: number;
  items: T[];
}

export interface IAuthApi {
  login(username: string, password: string): Promise<{ token: string; refreshToken: string }>;
  tokenRefresh(token: string, refreshToken: string): Promise<string>;
}

export interface IMetadataApi {
  fetchProjects(): Promise<ProjectModel[]>;
  createProject(data: ProjectModel, defaultContent?: boolean): Promise<ProjectModel>;
  updateProject(projectId: ApiId, name?: string, subtitle?: string, description?: string): Promise<ProjectModel>;
  deleteProject(projectId: ApiId): Promise<void>;
}

export interface IDataApi {
  queryStatistics(projectId: ApiId, datasetId: ApiId, filename: string, filter?: ExplorationFilter, includeLabels?: boolean, includeThemeTopicFrequency?: boolean, includeWordsStats?: boolean): Promise<DataStatisticsResult>;
  querySamples(projectId: ApiId, datasetId: ApiId, filename: string, start: number, count: number, filter?: ExplorationFilter, allColumns?: boolean): Promise<DataSamplesResult>;
}

export interface ILangApi {
  summaryOptions(): Promise<LangTextSummaryAvailableOptions>;
  querySummaryStatistics(projectId: ApiId, datasetId: ApiId, filename: string, ids: string[], options: LangTextAggregateOptions, numOutputTokens: number): Promise<LangTextSummaryStatistics>;
  generateSummary(projectId: ApiId, datasetId: ApiId, filename: string, ids: string[], options: LangTextAggregateOptions): Promise<LangTextSummmary>;
}

export interface IProjectApi {
  signedLink(url: string): Promise<string>;
  fetchProject(id: ApiId): Promise<ProjectModel>;

  fetchDataset(projectId: ApiId, datasetId: ApiId): Promise<DatasetModel>;
  fetchDatasets(projectId: ApiId, datasetId?: ApiId): Promise<ApiBatchResult<DatasetModel>>;
  createDataset(projectId: ApiId, data: DatasetModel): Promise<DatasetModel>;
  createProcessedDataset(projectId: ApiId, parentId: ApiId, name: string | undefined, description: string | undefined, processorId: ApiId, processorParams: Record<string, any>): Promise<DatasetModel>;
  updateDataset(projectId: ApiId, datasetId: number, name?: string, description?: string): Promise<DatasetModel>;
  deleteDataset(projectId: ApiId, datasetId: number): Promise<void>;
  uploadDatasetContent(projectId: ApiId, datasetId: ApiId, filename: string, data: ArrayBuffer): Promise<Response>;
  datasetDownloadUrl(projectId: ApiId, datasetId: ApiId): string;

  fetchJobs(projectId: ApiId, completed: boolean, pending: boolean, relatedEntity?: string, relatedIds?: ApiId[], jobIds?: ApiId[]): Promise<JobInfoModel[]>;
  fetchJob(projectId: ApiId, jobId: ApiId): Promise<JobInfoModel>;

  fetchProcessor(projectId: ApiId, processorId: ApiId): Promise<ProcessorModel>;
  fetchProcessors(projectId: ApiId): Promise<ApiBatchResult<ProcessorModel>>;
  createProcessor(projectId: ApiId, data: ProcessorModel): Promise<ProcessorModel>;
  deleteProcessor(projectId: ApiId, processorId: number): Promise<void>;
  uploadProcessorContent(projectId: ApiId, processorId: ApiId, filename: string, data: ArrayBuffer): Promise<Response>;
  processorDownloadUrl(projectId: ApiId, processorId: ApiId): string;

  fetchExploration(projectId: ApiId, explorationId: ApiId): Promise<ExplorationModel>;
  fetchExplorations(projectId: ApiId): Promise<ApiBatchResult<ExplorationModel>>;
  createExploration(projectId: ApiId, data: ExplorationModel): Promise<ExplorationModel>;
  deleteExploration(projectId: ApiId, explorationId: ApiId): Promise<ExplorationModel>;
  updateExploration(projectId: ApiId, explorationId: ApiId, name?: string, description?: string): Promise<ExplorationModel>;
  createExplorationSelection(projectId: ApiId, explorationId: ApiId, selection: ExplorationSelection): Promise<ExplorationSelection>;
  saveExplorationSelection(projectId: ApiId, explorationId: ApiId, selectionId: ApiId, selection: ExplorationSelection): Promise<ExplorationSelection>;
  deleteExplorationSelection(projectId: ApiId, explorationId: ApiId, selectionId: ApiId): Promise<void>;

  fetchPrompts(projectId: ApiId, type?: string): Promise<ApiBatchResult<PromptModel>>;
  fetchPrompt(projectId: ApiId, promptId: ApiId): Promise<PromptModel>;
  createPrompt(projectId: ApiId, data: PromptModel): Promise<PromptModel>;
  updatePrompt(projectId: ApiId, promptId: ApiId, data: PromptModel): Promise<PromptModel>;
  deletePrompt(projectId: ApiId, promptId: number): Promise<void>;
}