type ApiEndpoints = {
  metadata: string,
  auth: string,
  project: string,
  data: string,
  lang: string,
  job: string
}

export type ApiConfig = {
  endpoints: ApiEndpoints
}

export enum Environments {
  production = "production",
  local = "local"
};