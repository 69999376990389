interface ISession {
  get token(): string | null;
  set token(t: string | null);
  get refreshToken(): string | null;
  set refreshToken(t: string | null);
}

enum SessionKeys {
  token = "session.token",
  refreshToken = "session.refreshToken",
}

enum SessionMetaKeys {
  storage = "session.meta.storage"
}

interface SessionStorageProvider {
  storage: Storage,
  name: string
};

export const PersistentStorageProvider: SessionStorageProvider = Object.freeze({
  storage: localStorage,
  name: "persistent"
});

export const EphemeralStorageProvider: SessionStorageProvider = Object.freeze({
  storage: sessionStorage,
  name: "ephemeral"
});

const AllSessionKeys = [SessionKeys.token, SessionKeys.refreshToken];

export class Session implements ISession {
  private storage: Storage;
  private provider: SessionStorageProvider;

  constructor(provider: SessionStorageProvider) {
    this.storage = provider.storage;
    this.provider = provider;

  }
  get token(): string | null {
    return this.storage.getItem(SessionKeys.token);
  }
  get refreshToken(): string | null {
    return this.storage.getItem(SessionKeys.refreshToken);
  }
  private _changeStorageProvider(provider: SessionStorageProvider) {
    if (this.provider.name === provider.name) {
      return;
    }
    AllSessionKeys.forEach((k) => {
      const v = this.storage.getItem(k);
      if (v) {
        provider.storage.setItem(k, v);
      }
      this.clear();
      this.storage = provider.storage;
    });
    this.provider = provider;
    this.storage = provider.storage;
  }
  clear() {
    AllSessionKeys.forEach((k) => {
      this.storage.removeItem(k);
    });
  }
  set(token: string, refreshToken: string) {
    this.storage.setItem(SessionKeys.token, token);
    this.storage.setItem(SessionKeys.refreshToken, refreshToken);
  }

  private static _sharedInstance: Session | null = null;

  public static createShared(provider: SessionStorageProvider) {
    this._sharedInstance = new Session(provider);
    localStorage.setItem(SessionMetaKeys.storage, provider.name);
  }

  public static storageProviderByName(name: string | undefined | null): SessionStorageProvider {
    switch (name) {
      case PersistentStorageProvider.name:
        return PersistentStorageProvider;
      case EphemeralStorageProvider.name:
        return EphemeralStorageProvider;
    }
    return EphemeralStorageProvider;
  }

  public static getDefaultStorageProvider(): SessionStorageProvider {
    return Session.storageProviderByName(localStorage.getItem(SessionMetaKeys.storage));
  }

  public static changeStorageProvider(instance: Session, name: string) {
    const provider = Session.storageProviderByName(name);
    instance._changeStorageProvider(provider);
    localStorage.setItem(SessionMetaKeys.storage, provider.name);
  }

  public static shared(): Session {
    return Session._sharedInstance!;
  }
}
