import { Box, Stack } from "@mui/material";
import { PieChart, PieChartSlotProps } from "@mui/x-charts";
import { FC } from "react";
import { CustomChip, ThemeChip } from "../../../../components/common/Tag";
import { StableColorMap } from "../../../../util/colormap";

export type ThemeChartProps = {
  rows: [string, number][];
  themeColorFn: StableColorMap;
  maxChipWidth?: string;
  horizontal?: boolean;
  width?: number;
  height?: number;
};

export const ThemeChart: FC<ThemeChartProps> = ({ rows, themeColorFn, maxChipWidth = "20em", width= 200, height=225, horizontal=false }) => {
  const slotProps: PieChartSlotProps = {
    legend: {
      hidden: true,
    },
  };
  const palette: string[] = rows.map(([id, value]) => themeColorFn(id));
  const scale = width / 200;
  return (
    <Stack sx={{}} direction={horizontal ? "row": "column"} justifyContent="flex-start" alignItems="center">
      <PieChart
        series={[
          {
            data: rows.map(([id, value]) => ({ id, value, label: id })),
            innerRadius: 40 * scale,
            outerRadius: 80 * scale,
            paddingAngle: 5,
            cornerRadius: 5,
            cx: width * 0.5,
            cy: height * 0.5,
          },
        ]}
        colors={palette}
        width={width}
        height={height}
        slotProps={slotProps}
        sx={{flexGrow: 0}}
      />
      <Stack direction="column" justifyContent="flex-start" sx={{flexGrow: 1}}>
        {rows.map(([id, value], i) => (
          <Box key={id} sx={{width: 'fit-content'}}>
            <ThemeChip label={id} sx={{ maxWidth: maxChipWidth }} markerColor={themeColorFn(id)} />
          </Box>
        ))}
      </Stack>
    </Stack>
  );
}