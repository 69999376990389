import { Box, SxProps, Theme } from "@mui/material";
import { FC } from "react";

export const CanucciLogo : FC<{ width: number, sx?: SxProps<Theme> }> = ({ width, sx }) => {
  return (
    <Box sx={{lineHeight: 0, ...sx}}>
      <img src="/canucci-logo.png" alt="Canucci logo" style={{ width: `${width}px` }} />
    </Box>
  );
}
