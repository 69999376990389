import ChevronRight from "@mui/icons-material/ChevronRight";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import React, { ReactNode, useState } from "react";
import { ApiId, NamedItemModel, StandardItemModel } from "../../api/apimodels";
import { ApiLoadingState } from "../../api/types";
import { useProject } from "../../state/projectstate";
import { formatJsonDate } from "../../util/dateformat";
import CollapsedListRow from "./CollapsedListRow";
import { DateText } from "./DateText";

import styled from "@emotion/styled";
import ErrorIcon from "@mui/icons-material/Error";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";


type Props<T extends NamedItemModel> = {
  onSelectResource: (id: number) => void;
  viewAll?: boolean;
  createRow?: ReactNode;
  filter?: ReactNode;
  forceExpand: boolean;
  icon?: any;
  iconFn?: (item: T) => ReactNode;
  title: string;
  items: T[];
  maxItems?: number;
  createLabel: string;
  showCreatedDate?: boolean;
  isLoadingFn: (item: T) => boolean;
};

function ResourceList<T extends NamedItemModel>(props: Props<T>) {
  const theme = useTheme();
  const { loadingState } = useProject();
  const { forceExpand, createRow, onSelectResource, showCreatedDate, createLabel, title, icon, iconFn, isLoadingFn, viewAll, filter } = props;
  const [showCreateRow, setShowCreateRow] = useState(false);

  const items =
    props.maxItems && props.maxItems < props.items.length ? props.items.slice(0, props.maxItems) : props.items;

  function onClickItem(e: React.MouseEvent<HTMLElement>, id: number) {
    e.preventDefault();
    e.stopPropagation();
    onSelectResource(id);
  }
  return (
    <Paper
      sx={{
        p: 2,
        display: "flex",
        flexDirection: "column",
        "& .MuiStack-root.title": { marginTop: 0, marginBottom: 2, width: "100%" },
        "& .MuiListItem-root": { pl: 0, alignSelf: "flex-end" },
      }}
    >
      <List dense={false} sx={{py: 0}}>
        <Stack className="title" direction="row" alignItems="center" gap={2}>
          <Typography className="title" variant="h6" flexGrow={1}>
            {title}
          </Typography>
          {filter}
        </Stack>
        {loadingState === ApiLoadingState.loading ? (
          <CircularProgress size={20} />
        ) : (
          <>
            {(items ?? []).map((d) => (
              <ListItem
                key={d.id}
                onClick={() => onSelectResource(d.id!)}
                secondaryAction={
                  <IconButton edge="end" aria-label="select" onClick={(e) => onClickItem(e, d.id!)}>
                    <ChevronRight />
                  </IconButton>
                }
              >
                <ListItemAvatar>
                  {isLoadingFn(d!) ? (
                    <CircularProgress />
                  ) : (
                    <Avatar sx={{ color: theme.palette.text.primary }}>{iconFn ? iconFn!(d) : icon}</Avatar>
                  )}
                </ListItemAvatar>
                <ListItemText primary={d.name} secondary={
                  <span>
                    <Typography component="span">
                    {d.description}
                    </Typography>
                    <DateText>
                    {showCreatedDate && formatJsonDate((d as StandardItemModel).created_at ?? "")}
                    </DateText>
                  </span>
                } />
              </ListItem>
            ))}
            {viewAll && items.length > 0 && (
              <ListItem key="__viewAll" onClick={() => {}} secondaryAction={
                <Button variant="text" sx={{mr: -1}} color="inherit">View all</Button>
              } sx={{mt: 1}}>
              </ListItem>
            )}
            {createRow && <Divider light sx={{ mt: 1, mb: 1 }} />}
            {createRow && (
              <CollapsedListRow
                label={createLabel}
                disableCollapse={forceExpand}
                expanded={showCreateRow}
                onClick={() => setShowCreateRow(forceExpand || !showCreateRow)}
              />
            )}
            {createRow && (forceExpand || showCreateRow) && <ListItem key="___create">{createRow}</ListItem>}
          </>
        )}
      </List>
    </Paper>
  );
}

type AccordionItemProps<T> = {
  item: T
  isError?: boolean;
  sx?: SxProps<Theme>;
  showCreatedDate?: boolean;
  initialOpen?: boolean;
  insetDetails?: boolean;
  onItemExpand?: (id: ApiId, expanded: boolean) => void;
  iconFn?: (id: ApiId) => ReactNode | undefined;
  editDialogFn?: (id?: ApiId) => ReactNode | undefined;
  menuFn?: (id?: ApiId) => ReactNode[]  | undefined;
  detailsFn?: (id?: ApiId) => ReactNode | undefined;
}

const ListItemML0 = styled(ListItem)(({ theme }) => ({
  paddingLeft: 0,
}));

export function AccordionResourceItem<T extends StandardItemModel>(props: AccordionItemProps<T>) {
  const {item: {id, name, description, created_at}, sx, isError, showCreatedDate, initialOpen, insetDetails, onItemExpand, iconFn, editDialogFn, menuFn, detailsFn } = props;
  const [expanded, setExpanded] = useState(!!initialOpen);
  const details = detailsFn && detailsFn(id);
  const menu = (menuFn && menuFn(id)) ?? [];
  const editDialog = editDialogFn && editDialogFn(id);
  const theme = useTheme();

  return (
    <Accordion sx={sx} expanded={expanded} onChange={() => {
        (onItemExpand && onItemExpand(id!, !expanded));
        setExpanded(!expanded);
      }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {isError && <ErrorIcon color="error" fontSize="small" sx={{ mr: 2 }} />}
        <ListItemML0 sx={{py: 0}}>
          {iconFn && (
            <ListItemAvatar>
              <Avatar sx={{ color: theme.palette.text.primary }}>{iconFn!(id!)}</Avatar>
            </ListItemAvatar>
          )}
          <ListItemText primary={name} secondary={<span>
            <Typography component="span">
              {description}
            </Typography>
            <DateText>
              {showCreatedDate && formatJsonDate(created_at ?? "")}
            </DateText>
          </span>} 
          />
        </ListItemML0>
      </AccordionSummary>
      <AccordionDetails sx={{ pt: 0 }}>
        <Box sx={{mx: insetDetails ? 7 : 0}}>
          {!showCreatedDate && (<DateText sx={{ m: 0, mb: 2 }}>Created: {formatJsonDate(created_at!)}</DateText>) }
          {details}
          {menu}
          {editDialog}
        </Box>
      </AccordionDetails>
    </Accordion>
  )
}

type AccordionResourceListProps<T> = {
  title: string;
  items: T[];
  filter?: ReactNode;
  showCreatedDate?: boolean;
  initialOpenId?: ApiId;
  insetDetails?: boolean;
  onItemExpand?: (id: ApiId, expanded: boolean) => void;
  iconFn?: (id: ApiId) => ReactNode | undefined;
  editDialogFn?: (id?: ApiId) => ReactNode | undefined;
  menuFn?: (id?: ApiId) => ReactNode[]  | undefined;
  detailsFn?: (id?: ApiId) => ReactNode | undefined;
};

export function AccordionResourceList<T extends StandardItemModel>(props: AccordionResourceListProps<T>) {
  const { title, filter, items, initialOpenId, insetDetails, onItemExpand, iconFn, editDialogFn, menuFn, detailsFn, showCreatedDate } = props;
  return <Box>
    <Stack className="title" direction="row" alignItems="center" gap={2} sx={{pb: 2}}>
      <Typography className="title" variant="h6" flexGrow={1}>
        {title}
      </Typography>
      {filter}
    </Stack>
    {items.map((i) => (
      <AccordionResourceItem 
        initialOpen={(initialOpenId === i.id)} 
        key={i.id!} 
        item={i} 
        onItemExpand={onItemExpand} 
        iconFn={iconFn} 
        insetDetails={insetDetails}
        detailsFn={detailsFn} 
        editDialogFn={editDialogFn} 
        menuFn={menuFn} 
        showCreatedDate={showCreatedDate} />
    ))}
  </Box>;
}

export default ResourceList;

