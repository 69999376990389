import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, styled, TextField } from "@mui/material";
import { useEffect, useState } from "react";

const WideDialog = styled(Dialog)(({ theme }) => ({
  "& form.MuiDialog-paper": {
    minWidth: '800px',
    maxWith: '800px',
    [theme.breakpoints.down('md')]: {
      minWidth: '90%',
      maxWith: 'none'
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: '95%',
      maxWith: 'none'
    },
  }
}));

type EditDetailsDialogProps = {
  open: boolean;
  dialogTitle: string;
  name?: string;
  description?: string;
  onClose: () => void;
  onDelete: () => void;
  onSave: (name: string, description: string) => void;
}

export function EditDetailsDialog(props: EditDetailsDialogProps) {
  const { open, onClose, onSave, onDelete, dialogTitle, name, description } = props;
  const [currentName, setCurrentName] = useState(name ?? "");
  const [currentDescription, setCurrentDescription] = useState(description ?? "");
  
  useEffect(() => {
    if (open) {
      setCurrentName(name ?? "");
      setCurrentDescription(description ?? "");
    } else {
      setCurrentName("");
      setCurrentName("");
    }
  }, [open, name, description]);

  return (
      <WideDialog
          open={open}
          onClose={onClose}
          PaperProps={{
              component: 'form',
              onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                  event.preventDefault();
                  onSave(currentName, currentDescription);
              },
          }}
      >
          <DialogTitle>{dialogTitle}</DialogTitle>
          <DialogContent>
              <TextField required margin="dense" id="name" name="name" label="Name" type="text" fullWidth value={currentName} onChange={(e) => { setCurrentName(e.target.value); }} variant="standard" />
              <TextField multiline margin="dense" id="description" name="description" label="Description" type="text" value={currentDescription} onChange={(e) => { setCurrentDescription(e.target.value); }} fullWidth variant="standard" />
          </DialogContent>
          <DialogActions>
              <Box sx={{flexGrow: 1, ml: 1}}>
                <Button onClick={onDelete} color="error">Delete</Button>
              </Box>
              <Button onClick={onClose}>Cancel</Button>
              <Button type="submit">Save</Button>
          </DialogActions>
      </WideDialog>
  );
}