import { boolStr } from "../../util/params";
import { ApiId, ProjectModel } from "../apimodels";
import { standardDeleteOptions, standardGetOptions, standardPostOptions, standardPutOptions, throwApiError } from "../helpers";
import { FetchFn, IMetadataApi } from "../types";

export class MetadataApi implements IMetadataApi {
  private baseUrl: string;
  private fetchFn: FetchFn;
  public ver: number;

  constructor(baseUrl: string, fetchFn: FetchFn | undefined) {
    this.baseUrl = baseUrl;
    this.fetchFn = fetchFn ?? ((input, init) => fetch(input, init));
    this.ver = Math.random() * 10000000;
  }

  // TODO: Change to BatchResult
  async fetchProjects(): Promise<ProjectModel[]> {
    const url = `${this.baseUrl}/project`;
    const response = await this.fetchFn(url, standardGetOptions());
    const data = await response.json();
    if (!response.ok) {
      throwApiError(response.status);
    }
    return data as ProjectModel[];
  }

  async createProject(data: ProjectModel, defaultContent?: boolean): Promise<ProjectModel> {
    const url = `${this.baseUrl}/project?add_default_content=${boolStr(defaultContent)}`;
    const options = standardPostOptions({ body: JSON.stringify(data) });
    const response = await this.fetchFn(url, options);
    if (!response.ok) {
      throwApiError(response.status);
    }
    return (await response.json()) as ProjectModel;
  }

  async updateProject(projectId: ApiId, name?: string, subtitle?: string, description?: string): Promise<ProjectModel> {
    const url = `${this.baseUrl}/project/${projectId}`;
    const options = standardPutOptions({ body: JSON.stringify({name, subtitle, description}) });
    const response = await this.fetchFn(url, options);
    if (!response.ok) {
      throwApiError(response.status);
    }
    return (await response.json()) as ProjectModel;
  }

  async deleteProject(projectId: ApiId): Promise<void> {
    const url = `${this.baseUrl}/project/${projectId}`;
    const options = standardDeleteOptions();
    const response = await this.fetchFn(url, options);
    if (!response.ok) {
      throwApiError(response.status);
    }
    return;
  }

}