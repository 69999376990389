import { Box, TextField, Typography } from "@mui/material";
import { ProcessorBooleanParameter, ProcessorEnumParameter, ProcessorFloatParameter, ProcessorIntegerEnumParameter, ProcessorIntegerParameter, ProcessorManifest, ProcessorParameterDescriptor, ProcessorParameterType } from "../../api/apimodels";
import { BooleanDropdown } from "../common/inputs/BooleanDropdown";
import { NumericDropdown } from "../common/inputs/NumericDropdown";
import { EnumDropdown } from "../common/inputs/EnumDropdown";

type Props = {
  manifest: ProcessorManifest;
  className?: string;
  disabled?: boolean;
  config: string | undefined;
  onChange: (value: string) => void;
};

type NumberChangeFn = (name: string, value: number | undefined) => void;
type BooleanChangeFn = (name: string, value: boolean | undefined) => void;
type EnumChangeFn = (name: string, value: string | undefined) => void;
type ChangeFn = NumberChangeFn | BooleanChangeFn | EnumChangeFn;

function integerParamComponent(param: ProcessorIntegerParameter, value: number | undefined, handleChange: NumberChangeFn, disabled: boolean = false) {
  let numValues = -1;
  if (param.maxValue !== undefined && param.minValue !== undefined) {
    numValues = param.maxValue - param.minValue;
  }
  if (numValues > 0 && numValues < 20) {
    return <NumericDropdown
      id={param.name}
      key={param.name}
      value={value ?? param.defaultValue}
      minMax={[param.minValue!, param.maxValue!]}
      label={param.displayName ?? param.name}
      disabled={disabled}
      onChange={handleChange}
    />
  } else {
    return <TextField
    id={param.name}
    key={param.name}
    label={param.displayName}
    disabled={disabled}
    value={value ?? param.defaultValue}
    onChange={(e) => handleChange(param.name, parseInt(e.target.value))}
    variant="filled"
  />
  }
}

function floatParamComponent(param: ProcessorFloatParameter, value: number | undefined, handleChange: (name: string, value: number | undefined) => void, disabled: boolean = false) {
  return <TextField
    id={param.name}
    key={param.name}
    label={param.displayName}
    disabled={disabled}
    value={value ?? param.defaultValue}
    onChange={(e) => handleChange(param.name, parseFloat(e.target.value))}
    variant="filled"
  />
}

function enumParamComponent(param: ProcessorEnumParameter, value: string | undefined, handleChange: (name: string, value: string | undefined) => void, disabled: boolean = false) {
  return <EnumDropdown 
    id={param.name}
    key={param.name}
    disabled={disabled}
    value={value ?? param.defaultValue} 
    options={param.values} 
    label={param.displayName ?? param.name} 
    onChange={handleChange}
  />;
}

function integerEnumParamComponent(param: ProcessorIntegerEnumParameter, value: number | undefined, handleChange: (name: string, value: number | undefined) => void, disabled: boolean = false) {
  if (!param.values && (param.minValue === undefined && param.maxValue === undefined)) {
    throw new Error("Need either values or minValue and maxValue set for INTEGER_ENUM parameter. Check processor manifest.json"); 
  }
  let values = param.values;
  if (!values) {
    values = Array.from({length: (1 + param.maxValue!) - param.minValue!}).map((_, i) => (param.minValue! + i));
  }
  return <EnumDropdown 
    id={param.name}
    key={param.name}
    disabled={disabled}
    value={(value ?? param.defaultValue) + ""} 
    options={values.map((v) => (v + ""))} 
    label={param.displayName ?? param.name} 
    onChange={(id, value) => (handleChange(id, parseInt(value)))}
  />;
}

function booleanParamComponent(param: ProcessorBooleanParameter, value: boolean | undefined, handleChange: (name: string, value: boolean | undefined) => void, disabled: boolean = false) {
  return <BooleanDropdown 
    id={param.name}
    key={param.name}
    disabled={disabled} 
    value={value ?? param.defaultValue} 
    falseLabel={param.labels ? param.labels[0] : 'False'}
    trueLabel={param.labels ? param.labels[1] : 'True'}
    label={param.displayName ?? param.name} 
    onChange={handleChange}
  />;
}

function parameterToComponent(descriptor: ProcessorParameterDescriptor, value: number | boolean | string | undefined, handleChange: ChangeFn, disabled: boolean) {
  switch (descriptor.type) {
    case ProcessorParameterType.INTEGER:
      return integerParamComponent(descriptor as ProcessorIntegerParameter, value as number | undefined, handleChange as NumberChangeFn, disabled);
    case ProcessorParameterType.INTEGER_ENUM:
      return integerEnumParamComponent(descriptor as ProcessorIntegerEnumParameter, value as number | undefined, handleChange as NumberChangeFn, disabled);
    case ProcessorParameterType.FLOAT:
      return floatParamComponent(descriptor as ProcessorFloatParameter, value as number | undefined, handleChange as NumberChangeFn, disabled);
    case ProcessorParameterType.ENUM:
      return enumParamComponent(descriptor as ProcessorEnumParameter, value as string | undefined, handleChange as EnumChangeFn, disabled);
    case ProcessorParameterType.BOOLEAN:
      return booleanParamComponent(descriptor as ProcessorBooleanParameter, value as boolean | undefined, handleChange as BooleanChangeFn, disabled);
  }
  return <Typography color="error">Unknown parameter type</Typography>
}

function lookupDefaultValue(name: string, manifest: ProcessorManifest) {
  const descriptor = manifest.parameters.find((p) => p.name === name);
  return descriptor ? descriptor.defaultValue : undefined;
}

export function applyFormatter(formatter: string, config: string | undefined, manifest: ProcessorManifest) {
  if (!config || !manifest.formatters || !manifest.formatters[formatter]) {
    return;
  }
  const cfg = JSON.parse(config);
  const fmt: string = manifest.formatters[formatter];
  return fmt.replace(/{(\w+)}/g, (match, key) => {
    const value = key in cfg ? cfg[key] : null;
    return (value === undefined || value === null) ? lookupDefaultValue(key, manifest) : value;
});
}

export function configToVersionName(config: string | undefined, manifest: ProcessorManifest) {
  return applyFormatter("name", config, manifest);
}

export function configToDescription(config: string | undefined, manifest: ProcessorManifest) {
  return applyFormatter("description", config, manifest);
}

export default function ProcessorParameters(props: Props) {
  const { onChange, disabled } = props;
  const parameters = props.manifest.parameters ?? [];
  const defaultConfig: Record<string, any> = {};
  parameters.forEach((p) => { defaultConfig[p.name] = p.defaultValue; });
  const config = {
    ...defaultConfig, 
    ...((props.config && props.config !== "{}") ? (JSON.parse(props.config) as Record<string, any>) : {})
  };

  function handleChange(id: string, value: any) {
    let newConfig: Record<string, any> = config;
    newConfig = { ...config };
    newConfig[id] = value;
    onChange(JSON.stringify(newConfig));
  }
  const components = parameters.map((d) => parameterToComponent(d, config[d.name], handleChange, !!disabled));
  const widths = parameters.map((d) => (d.width ? `${d.width * 100.0}%` : 'auto'));

 return <Box sx={{ml: 0, mx: -0.5}}>
    {components.map((c, i) => (<Box display="inline-block" sx={{width: widths[i], px: 0.5, my: 0.5}} key={i}>{c}</Box>))}
 </Box>
}
