import { Box, Button, Container, Divider, FormControl, Grid, InputLabel, ListItemIcon, ListItemText, MenuItem, Select, SelectChangeEvent, Stack, SxProps, TextField, Theme, Tooltip, Typography } from "@mui/material";
import { ChangeEvent, FC, ReactNode, useCallback, useState } from "react";
import { useLoaderData, useLocation, useNavigate, useParams, useRouteLoaderData, useSearchParams } from "react-router-dom";
import { ApiId, ProjectModel, PromptModel } from "../../api/apimodels";
import { Apis } from "../../api/apis";
import { ApiBatchResult, ApiGeneralError } from "../../api/types";
import ResourceCreateForm from "../../components/common/ResourceCreateForm";
import { AccordionResourceList } from "../../components/common/ResourceList";
import { intOrUndefined } from "../../util/params";
import { sortedResource } from "../../util/sorting";
import { PromptPage as PromptPageInfo } from "../PageInfo";
import { ConfirmDeleteDialog, EditPromptDialog } from "./EditPromptDialog";
import { defaultPrompt, OrderedPromptTypes, PromptType, promptTypeInfo } from "./PromptTypes";

export type PromptTypeSelectProps = {
  type: PromptType | "all";
  label: string;
  size?: "medium" | "small" | undefined;
  showAllOption?: boolean;
  onChange: (type: PromptType) => void;
  tooltipPlacement?: "left" | "right" | "top";
  sx?: SxProps<Theme>;
};

const renderListItem = (name: string, icon: ReactNode) => {
  return <Stack sx={{ width: "100%" }} direction="row" alignItems="center">
    <ListItemIcon>{icon}</ListItemIcon>
    <ListItemText>{name}</ListItemText>
  </Stack>
}

export const PromptTypeSelect: FC<PromptTypeSelectProps> = ({type, label, onChange, tooltipPlacement, sx, showAllOption, size = "medium"}) => {
  return (
    <FormControl sx={sx}>
      <InputLabel id="create-resource-prompt-type-label">{label}</InputLabel>
      <Select
        labelId="create-resource-prompt-type-label"
        id="demo-simple-select"
        size={size}
        value={type as PromptType}
        renderValue={(v: string) => {
          const [, icon, name] = promptTypeInfo(v as PromptType);
          return (
            v === 'all' ? (
              <Stack direction="row" alignItems="center" gap={1}>
                <Typography>All types</Typography>
              </Stack>
            ) : (
              <Stack direction="row" alignItems="center" gap={1}>
                {icon}
                <Typography>{name}</Typography>
            </Stack>
            ))
        }}
        placeholder="this is a placeholder"
        label={label}
        autoWidth
        onChange={(e: SelectChangeEvent<PromptType>) => onChange(e.target.value as PromptType)}
      >
        {showAllOption && (
          <MenuItem value={"all"} key={"all"}>
            <Stack sx={{ width: "100%" }} direction="row">
              <ListItemText>All types</ListItemText>
            </Stack>
        </MenuItem>
        )}
        {OrderedPromptTypes.map(promptTypeInfo).map(([id, icon, name, info]) => (
          <MenuItem value={id} key={id}>
            {!!tooltipPlacement ? (
              <Tooltip title={info} placement={tooltipPlacement}>
                {renderListItem(name, icon)}
                </Tooltip>
              )
              : renderListItem(name, icon)
            }
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export const CreatePromptForm: FC<{tooltipPlacement: "left" | "right"}> = ({tooltipPlacement}) => {
  const api = Apis.shared().project;
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [ error, setError ] = useState<Error | undefined>();
  const [ type, setType ] = useState(PromptType.manyToOneSummary);
  const [ text, setText ] = useState(defaultPrompt)
  const projectId: number | undefined = params.projectId ? parseInt(params.projectId) : undefined;

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent, setFn: React.Dispatch<React.SetStateAction<string>>) => {
    e.stopPropagation();
    e.preventDefault();
    setFn(e.target.value ?? "");
  }

  const onCreatePrompt = useCallback((name: string, description: string) => {
    const asyncFn = async () => {
      const prompt: PromptModel = { name, description, type, text };
      setError(undefined);
      if (projectId) {
        try {
          await api.createPrompt(projectId, prompt);
          navigate(location, { replace: true });
        } catch (e: any) {
          const apiError = e as ApiGeneralError;
          setError(new Error(`Upload failed, status code ${apiError.statusCode}, message: ${apiError.message}`));
          console.error("Upload of model failed", apiError);
        }
      }
    };
    return asyncFn();
  }, [api, location, navigate, projectId, text, type]);

  return (
    <ResourceCreateForm
      title="Add prompt"
      icon={PromptPageInfo.menuIcon}
      embedded={false}
      error={error}
      onCreate={onCreatePrompt}
      childrenPlacement="post"
      resourceNameLabel={"Prompt name"}
      resourceDescriptionLabel={"Prompt description"}
      createButtonLabel={"Create prompt"}
      noBottomDivider
    >
      <TextField
        id="create-resource-prompt-text"
        label="Prompt text"
        variant="filled"
        multiline
        value={text}
        onChange={(e) => onChange(e, setText)}
        sx={{ pb: 0 }}
      />
      <Box sx={{mt: 1, mb: 2}}>
        <PromptTypeSelect size="small" label="Type" type={type} onChange={setType} tooltipPlacement={tooltipPlacement} />
      </Box>
    </ResourceCreateForm>
  );
}

export default function PromptsPage() {
  const project = useRouteLoaderData('project') as ProjectModel;
  const { items: prompts } = useLoaderData() as ApiBatchResult<PromptModel>;
  const [ searchParams, setSearchParams ] = useSearchParams();
  const [ editItem, setEditItem ] = useState<PromptModel | undefined>();
  const [ deleteItem, setDeleteItem ] = useState<PromptModel | undefined>();
  const navigate = useNavigate();
  const location = useLocation();
  const promptType: PromptType | "all" = searchParams.has('type') ? searchParams.get('type') as PromptType | "all" : "all";
  const initialOpenId = intOrUndefined(searchParams.get('selected'));
  const items = sortedResource(prompts, true);
  const itemsLookup: Record<string, PromptModel> = {};
  items.forEach((i) => { itemsLookup[i.id!] = i});

  const setPromptType = (type: PromptType | "all") => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set('type', type);
    setSearchParams(newSearchParams);
    navigate({...location, search: newSearchParams.toString()}, {replace: true});
  }

  const onChangeFilter = (type: PromptType | "all") => {
    setPromptType(type);
  }

  const onDeletePrompt = useCallback(() => {
    const asyncFn = async () => {
      try {
        await Apis.shared().project.deletePrompt(project.id!, deleteItem!.id!);
        setDeleteItem(undefined);
        navigate(location, {replace: true});
      } catch (e) {
        console.error(e);
      }
    };
    asyncFn();
  }, [deleteItem, location, navigate, project.id]);

  const onSavePrompt = useCallback((name: string, description: string, text: string, type: PromptType) => {
    const asyncFn = async () => {
      try {
        await Apis.shared().project.updatePrompt(project.id!, editItem!.id!, {
          name, description, text, type
        });
        setEditItem(undefined);
        navigate(location, {replace: true});
      } catch (e) {
        console.error(e);
      }
    };
    asyncFn();
  }, [editItem, location, navigate, project.id]);

  const onItemExpand = useCallback((id: ApiId, expanded: boolean) => {
      const newSp = new URLSearchParams(searchParams);
      if (expanded) {
        newSp.set('selected', id + '');
      } else {
        newSp.delete('selected');
      }
      setSearchParams(newSp);
    }, [searchParams, setSearchParams]
  );

  return (
    <Box sx={{ display: "flex" }}>
      <Box component="main" sx={{flexGrow: 1, overflow: "auto" }}>
        <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
          <Grid container spacing={3}>
              <Grid item xs={12} md={8} lg={8}>
                <AccordionResourceList
                  title="Prompts"
                  filter={<PromptTypeSelect size="small" label="" type={promptType} onChange={onChangeFilter} sx={{minWidth: '10em'}} showAllOption tooltipPlacement="left" />}
                  items={items}
                  initialOpenId={initialOpenId}
                  onItemExpand={onItemExpand}
                  insetDetails={true}
                  iconFn={(id: ApiId) => (promptTypeInfo(itemsLookup[id].type)[1])} 
                  detailsFn={(id: ApiId | undefined) => {
                    return <Box>
                      <Divider sx={{mb: 2}} />
                      <Typography variant="subtitle2">PROMPT:</Typography>
                      <Typography variant="body1" sx={{whiteSpace: 'pre-line'}}>{itemsLookup[id!].text}</Typography>
                    </Box>;
                  }}
                  editDialogFn={(id: ApiId | undefined) => {
                    return <Stack direction="row" justifyContent="flex-end" sx={{mt: 2, width: '100%'}}>
                        <Button onClick={() => (setDeleteItem(itemsLookup[id!]))}>Delete</Button>
                        <Button onClick={() => (setEditItem(itemsLookup[id!]))}>Edit</Button>
                      </Stack>;
                  }}
                />
                {/*}
                <ResourceList
                  onSelectResource={onSelectModel}
                  title="Prompts"
                  icon={PromptPageInfo.menuIcon}
                  iconFn={(item) => (promptTypeInfo(item.type)[1])}
                  items={items}
                  showCreatedDate
                  filter={<PromptTypeSelect size="small" label="" type={promptType} onChange={onChangeFilter} sx={{minWidth: '10em'}} showAllOption tooltipPlacement="left" />}
                  createLabel="Add prompt"
                  isLoadingFn={(item) => (false)}
                  forceExpand={prompts.length === 0}
                />
                */}
              </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <CreatePromptForm tooltipPlacement={prompts.length > 0 ? 'left' : 'right'} />
            </Grid>
            {editItem && <EditPromptDialog open={true} dialogTitle="Edit prompt" prompt={editItem} onSave={onSavePrompt} onClose={() => setEditItem(undefined) } />}
            {deleteItem && <ConfirmDeleteDialog open={true} onDelete={onDeletePrompt} onCancel={() => setDeleteItem(undefined) } />}
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}
