import { ReactNode } from 'react';
import { PromptModel } from '../../api/apimodels';
// import SumIcon from '@mui/icons-material/Functions';
import SummarizeIcon from "@mui/icons-material/Summarize";
import QuoteIcon from "@mui/icons-material/FormatQuote";
import DialogIcon from '@mui/icons-material/Forum';
import TransformIcon from '@mui/icons-material/Reorder';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

export const defaultPrompt = `The following is a set of texts collected in a survey
{texts}
Please write a summary for the collection of all texts? Keep the summary very short: 3 sentences at most. 
Helpful Answer:
`;

export enum PromptType {
  manyToOneSummary = 'many_to_one_summary',
  manyToOneQuote = 'many_to_one_quote',
  manyToOneDialog = 'many_to_one_dialog',
  oneToOne = 'one_to_one',
}

export const defaultSummaryPromptModel: PromptModel = {
  name: 'Default',
  description: 'Default summary prompt',
  type: PromptType.manyToOneSummary,
  text: defaultPrompt
}

export const OrderedPromptTypes = [PromptType.manyToOneSummary, PromptType.manyToOneQuote, PromptType.manyToOneDialog, PromptType.oneToOne];


export function promptTypeInfo(type: PromptType | string): [string, ReactNode, string, string] {
  switch (type) {
    case PromptType.manyToOneSummary:
      return [type, <SummarizeIcon fontSize="small"/>, "Summary", "Produce a summary of multiple texts"];
    case PromptType.manyToOneQuote:
      return [type, <QuoteIcon fontSize="small"/>, "Quote", "Produce a summary of multiple texts in the form of a quote."];
    case PromptType.manyToOneDialog:
      return [type, <DialogIcon fontSize="small"/>, "Dialog", "Produce a summary of multiple texts in the form of a dialog between multiple participants (e.g. to highlight different aspects of the texts). "];
    case PromptType.oneToOne:
      return [type, <TransformIcon fontSize="small"/>, "Transform each", "Transform / rewrite *each* text. E.g. make it harder to guess who wrote a text by rewriting it, and/or shorten really long answers"];
    }
  return [type, <QuestionMarkIcon fontSize="small"/>, type, "New type - no name / description exists yet"];
}
