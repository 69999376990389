import { IconButton, ListItem, ListItemText, Typography } from "@mui/material";
import { UnfoldMore, UnfoldLess } from "@mui/icons-material";

type Props = {
  label: string;
  expanded: boolean;
  disableCollapse?: boolean;
  onClick: () => void;
};

function CollapsedListRow(props: Props) {
  const { label, disableCollapse, expanded, onClick} = props;
  return (
    <ListItem
      onClick={() => props.onClick()}
      secondaryAction={
        <IconButton edge="end" aria-label="toggle" onClick={disableCollapse ? onClick : undefined} disabled={disableCollapse}>
          {expanded ? <UnfoldLess /> : <UnfoldMore />}
        </IconButton>
      }
    >
      <ListItemText>
      <Typography variant="h6">{label}</Typography>
      </ListItemText>
    </ListItem>
  );
}

export default CollapsedListRow;
