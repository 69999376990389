import { FetchFn, IAuthApi, SignedLinkFn } from "../../api/types";
import { Session } from "./session";

type DecodedToken = {
  header: any;
  payload: any;
};

// TODO: Move token parsing to api call instead and store both encoded and decoded info in Application State

function parseb64(b64: string): string {
  return b64.replace(/-/g, "+").replace(/_/g, "/");
}

function decodeToken(token: string): DecodedToken | undefined {
  try {
    const [header, payload] = token
      .split(".")
      .slice(0, 2)
      .map(parseb64)
      .map(window.atob)
      .map((str) => JSON.parse(str));
    return { header, payload };
  } catch (e) {
    return undefined;
  }
}

enum AuthResult {
  invalid,
  shouldRefresh,
  ok,
}

export class NotAuthenticatedError extends Error {
  private _url: string | undefined;

  constructor(message: string, url?: string) {
    super(message);
    this._url = url;
  }

  public get url(): string | undefined {
    return this._url;
  }
}

export function isAuth(decoded: DecodedToken, expirationMargin: number = 120): AuthResult {
  try {
    const expire = decoded.payload.exp;
    const now = Date.now() / 1000.0;
    const diff = expire - now;
    if (diff > expirationMargin) {
      return AuthResult.ok;
    } else if (diff > 0) {
      return AuthResult.shouldRefresh;
    }
    return AuthResult.invalid;
  } catch (e) {
    console.error(e);
    return AuthResult.invalid;
  }
}

export enum FetchStatus {
  idle,
  started,
  authenticating,
  fetching,
};

export function createAuthRefreshFetchFn(
  authApi: IAuthApi,
  token: string | undefined | null,
  refreshToken: string | undefined | null,
  fetchStatusFn?: (status: FetchStatus) => void
): FetchFn {
  let state = { token };
  if (!refreshToken) {
    return () => {
      throw new NotAuthenticatedError("Not authorized", window.location.href);
    };
  }
  return (input, init) => {
    (fetchStatusFn && fetchStatusFn(FetchStatus.started));
    let decoded = state.token ? decodeToken(state.token) : undefined;
    const options = { ...init };
    let headers: any = { ...options.headers } ?? {};
    headers["Authorization"] = `Bearer ${state.token}`;
    options.headers = headers;
    if (!decoded || isAuth(decoded) !== AuthResult.ok) {
      (fetchStatusFn && fetchStatusFn(FetchStatus.authenticating));
      return authApi
        .tokenRefresh(token ?? "", refreshToken!)
        .then((newToken) => {
          state.token = newToken;
          Session.shared().set(newToken, refreshToken);
          headers["Authorization"] = `Bearer ${state.token}`;
          options.headers = headers;
          (fetchStatusFn && fetchStatusFn(FetchStatus.fetching));
          const result = fetch(input, options);
          (fetchStatusFn && fetchStatusFn(FetchStatus.idle));
          return result;
        })
        .catch((error) => {
          (fetchStatusFn && fetchStatusFn(FetchStatus.idle));
          console.warn(error);
          throw new NotAuthenticatedError("Token refresh failed - not authorized", window.location.href);
        });
    }
    (fetchStatusFn && fetchStatusFn(FetchStatus.fetching));
    return fetch(input, options).then((result) => {
      (fetchStatusFn && fetchStatusFn(FetchStatus.idle));
      if (result.status === 401) {
        throw new NotAuthenticatedError("Token refresh failed - not authorized", window.location.href);
      }
      return result;
    });
  };
}

export function createSignedRefreshLinkFn(
  authApi: IAuthApi,
  token: string | undefined | null,
  refreshToken: string | undefined | null
): SignedLinkFn {
  let state = { token };
  if (!refreshToken) {
    return () => {
      throw new NotAuthenticatedError("Not authorized", window.location.href);
    };
  }
  return (url: string) => {
    let decoded = state.token ? decodeToken(state.token) : undefined;
    let u = new URL(url);
    const options: any = {};
    let headers: any = {} ?? {};
    headers["Authorization"] = `Bearer ${state.token}`;
    options.headers = headers;
    if (!decoded || isAuth(decoded) !== AuthResult.ok) {
      return authApi
        .tokenRefresh(token ?? "", refreshToken!)
        .then((newToken) => {
          state.token = newToken;
          Session.shared().set(newToken, refreshToken);
          let u = new URL(url);
          u.searchParams.set("t", newToken);
          return u.toString();
        })
        .catch((error) => {
          console.warn(error);
          throw new NotAuthenticatedError("Token refresh failed - not authorized", window.location.href);
        });
    } else {
      u.searchParams.set("t", state.token!);
      return Promise.resolve(u.toString());
    }
  };
}
