function zeroPad(v: number, digits: number): string {
  const padding = "0000000000";
  let result = `${padding}${v}`;
  return result.substring(result.length - digits);
}

export function formatJsonDate(date: string): string {
  const refDate = new Date(date);
  const yyyy = refDate.getFullYear();
  const mm = zeroPad(refDate.getMonth() + 1, 2);
  const dd = zeroPad(refDate.getDate(), 2);
  const hh = zeroPad(refDate.getHours(), 2);
  const mi = zeroPad(refDate.getMinutes(), 2);
  const ss = zeroPad(refDate.getSeconds(), 2);
  return `${yyyy}-${mm}-${dd} ${hh}:${mi}:${ss}`;
}