import LogoutIcon from "@mui/icons-material/Logout";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { CSSObject, Theme, styled, useTheme } from "@mui/material/styles";
import MuiToolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { ReactComponent as PanelClosedIcon } from '../common/icons/PanelClosedIconOutline.svg';
import { ReactComponent as PanelOpenIcon } from '../common/icons/PanelOpenIconOutline.svg';

import { Stack, Tooltip, Zoom, useMediaQuery } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams, useRouteLoaderData } from "react-router-dom";
import { ProjectModel } from "../../api/apimodels";
import * as PageInfo from "../../pages/PageInfo";
import {
  ApplicationActionTypes,
  useApplicationDispatch,
} from "../../state/applicationstate";
import { CanucciLogo } from "./icons/CanucciLogo";

// TODO: Clean up this sloppy mess.

const drawerWidth = 180;

function getAvailablePagesSlugs(projectId: number | undefined): Set<string> {
  if (projectId) {
    let pages = new Set(PageInfo.TopLevelPages.map((p) => p.slug));
    return pages;
  }
  return new Set([PageInfo.OverviewPage.slug]);
}

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px + 64px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const Toolbar = styled(MuiToolbar)(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    paddingLeft: 0,
  },
}));

type NavigationItemProps = {
  slug: string;
  icon: any;
  name: string;
  open: boolean;
  color?: string;
  disabled?: boolean;
  onMenuItemClick: (e: React.MouseEvent<HTMLElement>, slug: string) => void;
};

function NavigationItem(props: NavigationItemProps) {
  const { slug, icon, name, open, onMenuItemClick, disabled, color } = props;
  return (
    <Tooltip title={name} placement="right" TransitionComponent={Zoom}>
      <ListItem key={slug} disablePadding sx={{ display: "block" }}>
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: open ? "initial" : "center",
            px: 2.5,
          }}
          onClick={(e) => onMenuItemClick(e, slug)}
          disabled={disabled}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 3 : "auto",
              justifyContent: "center",
              color,
            }}
          >
            {icon}
          </ListItemIcon>
          <ListItemText primary={name} sx={{ opacity: open ? 1 : 0, color }} />
        </ListItemButton>
      </ListItem>
    </Tooltip>
  );
}

type Props = {
  children: React.ReactNode;
};

// TODO: Hack - there is probably a better way of doing this. React Router is a bit bloated and I do not have time to wade through docs

function pageInfoFromPath(pathname: string | undefined): PageInfo.PageInfo | undefined {
  if (!pathname) {
    return undefined;
  }
  const lookup: Record<string, PageInfo.PageInfo> = {};
  PageInfo.TopLevelPages.forEach((i) => {
    lookup[i.slug] = i;
  });
  let pieces = pathname.split("?");
  const path = pieces.length > 0 ? pieces.shift()! : pathname;
  pieces = path.split("/");
  const slug = pieces.findLast((s) => lookup[s]);
  return slug ? lookup[slug] : undefined;
}

export default function NavigationFramework(props: Props) {
  const params = useParams();
  const projectId = params.projectId ? parseInt(params.projectId) : undefined;
  const applicationDispatch = useApplicationDispatch();
  const availablePagesSlugs = useMemo(() => getAvailablePagesSlugs(projectId), [projectId]);
  const navigate = useNavigate();
  const location = useLocation();
  const activePage = pageInfoFromPath(location.pathname);
  const theme = useTheme();
  const selectedColor = theme.palette.primary.main;
  const project = useRouteLoaderData("project") as ProjectModel || undefined;
  const [open, setOpen] = useState(false);
  const isNarrow = useMediaQuery(theme.breakpoints.between("xs", "lg"));
  const [isWide, setIsWide] = useState(!isNarrow);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const onMenuItemClick = (e: React.MouseEvent<HTMLElement>, slug: string) => {
    e.stopPropagation();
    e.preventDefault();
    switch (slug) {
      case PageInfo.OverviewPage.slug:
        navigate(PageInfo.overviewPath());
        break;
      case PageInfo.ProjectPage.slug:
        if (projectId) {
          navigate(PageInfo.projectPath(projectId));
        }
        break;
      case PageInfo.DatasetPage.slug:
        if (projectId) {
          navigate(PageInfo.datasetPath(projectId, undefined));
        }
        break;
      case PageInfo.ProcessorPage.slug:
        if (projectId) {
          navigate(PageInfo.processorPath(projectId, undefined));
        }
        break;
      case PageInfo.ExplorationPage.slug:
          if (projectId) {
            navigate(PageInfo.explorationPath(projectId, undefined));
          }
        break;
      case PageInfo.PromptPage.slug:
        if (projectId) {
          navigate(PageInfo.promptPath(projectId, undefined));
        }
      break;        
      case "/logout":
        applicationDispatch({ type: ApplicationActionTypes.logout });
        window.location.href = "/login"; // Force reload
    }
  };

  useEffect(() => {
    if (open && isNarrow) {
      setOpen(false);
    }
    if (!open && !isNarrow && !isWide) {
      setOpen(true);
    }
    setIsWide(!isNarrow);
  }, [isNarrow, isWide, open]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
            <Stack sx={{
                backgroundColor: theme.palette.background.default, 
                height: '100%', 
                minHeight: '64px', 
                width: '64px',
                alignItems: 'center', 
                p: 0,
                justifyContent: 'center'}}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={() => (open ? handleDrawerClose() : handleDrawerOpen()) }
                edge="start"
                sx={{ ml: 0 }}>
                {open ? <PanelOpenIcon width={20} height={20} /> : <PanelClosedIcon width={20} height={20} />}
              </IconButton>
            </Stack>
          <div>
            <Typography variant="h6" noWrap sx={{ml: 3, mr: 2, fontWeight: 600}} component="span">
              {project ? project.name : "Overview"}
            </Typography>
            <Typography variant="body1" noWrap component="span" display={{xs: 'none', sm: 'inline'}} sx={{opacity: 0.75, fontWeight: 100}}>
              {project ? project.subtitle : ''}
            </Typography>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <Box sx={{display: 'flex', direction: 'row', justifyContent: 'flex-start', width: '100%', alignItems: 'center'}}> 
            <CanucciLogo width={110} sx={{position: 'relative'}} />
          </Box>
        </DrawerHeader>
        <List>
          {PageInfo.OrganisationPages.map((p) => ({
            p,
            color: p.slug === activePage?.slug ? selectedColor : undefined,
          })).map(({ p, color }) => (
            <NavigationItem
              key={p.slug}
              slug={p.slug}
              name={p.name}
              icon={p.menuIcon}
              color={color}
              onMenuItemClick={onMenuItemClick}
              open={open}
              disabled={!availablePagesSlugs.has(p.slug)}
            />
          ))}
        </List>
        <Divider />
        <List>
          {PageInfo.ProjectPages.map((p) => ({
            p,
            color: p.slug === activePage?.slug ? selectedColor : undefined,
          })).map(({ p, color }) => (
            <NavigationItem
              key={p.slug}
              slug={p.slug}
              name={p.name}
              icon={p.menuIcon}
              color={color}
              onMenuItemClick={onMenuItemClick}
              open={open}
              disabled={!availablePagesSlugs.has(p.slug)}
            />
          ))}
        </List>
        <List sx={{ display: "flex", height: "100%", flexDirection: "column", justifyContent: "flex-end" }}>
          <NavigationItem
            slug={"/logout"}
            name={"Logout"}
            icon={<LogoutIcon />}
            onMenuItemClick={onMenuItemClick}
            open={open}
          />
        </List>
      </Drawer>
      <Box sx={{ width: "100%", pt: 7 }}>{props.children}</Box>
    </Box>
  );
}
