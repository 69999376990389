import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useNavigate, useRouteLoaderData } from "react-router-dom";
import { LangTextAggregateOptions, LangTextSummaryAvailableOptions, LangTextSummaryStatistics, ProjectModel, PromptModel } from "../../../../api/apimodels";
import { Apis } from "../../../../api/apis";
import { ApiGeneralError } from "../../../../api/types";
import { sortedResource } from "../../../../util/sorting";
import { createDebouncer } from "../../../../util/throttling";
import { promptPath } from "../../../PageInfo";
import { PromptType } from "../../../prompt/PromptTypes";
import { ModelOptions } from "./ModelOptions";


type SummaryStatsProps = {
  project_id: number;
  dataset_id: number;
  filename: string;
  ids: string[];
  options: LangTextAggregateOptions;
};

export const SummaryStats = (props: SummaryStatsProps) => {
  const { ids, project_id, dataset_id, filename, options } = props;
  const [loading, setLoading] = useState(true);
  const [statistics, setStatistics] = useState<LangTextSummaryStatistics | undefined>();
  const [error, setError] = useState<Error | undefined>();
  
  const costPrecision = 1000;
  const roundedCost = Math.round((statistics?.estimated_cost_usd ?? 0) * costPrecision) / costPrecision;
  const costLowerLimit = 1 / costPrecision;

  useEffect(() => {
    setLoading(true);
    setError(undefined);
    setStatistics(undefined);
    Apis.shared()
      .lang.querySummaryStatistics(project_id, dataset_id, filename, ids, options, 300)
      .then((result) => {
        setLoading(false);
        setStatistics(result);
      })
      .catch((error) => {
        setError(error);
      });
  }, [dataset_id, filename, ids, options, project_id]);

  return (
    <Box>
      {loading ? (
        <CircularProgress sx={{ alignSelf: "center" }} />
      ) : (
        <Box>
          {error && !statistics ? (
            <Typography variant="body1" color="error">
              Unable to fetch statistics: {error.message}
            </Typography>
          ) : (
            <Typography variant="body1">
              Estimated token count: {statistics!.estimated_token_count}<br />
              Estimated cost (USD): {roundedCost < costLowerLimit ? `< ${costLowerLimit}` : `${roundedCost}`}
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
};

type Props = {
  open: boolean;
  onClose: () => void;
  ids: string[];
  project_id: number;
  dataset_id: number;
  filename: string;
  type: PromptType;
};

function isPromptError(e: Error | undefined) {
  if (e instanceof ApiGeneralError) {
    const apiError = e as ApiGeneralError;
    if (apiError.details) {
      return apiError.details['type'] === 'prompt';
    }
  }
  return false;
}

type SavePromptDialogProps = {open: boolean, onClose: () => void, onSave: (name: string, description: string) => void, text: string, original: PromptModel | undefined};

const SavePromptDialog = (props: SavePromptDialogProps) => {
  const {open, original, onClose, onSave} = props;
  const [name, setName] = useState(original ? original.name + " Copy" : "");
  const [description, setDescription] = useState(original ? original.description : "");
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Save prompt preset</DialogTitle>
      <DialogContent sx={{minWidth: 400}}>
        <Stack direction="column" gap={2}>
          <TextField label="Name" value={name} variant="standard" fullWidth onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => (setName(e.target.value))}/>
          <TextField label="Description" multiline value={description} variant="standard" fullWidth onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => (setDescription(e.target.value))}/>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={() => onSave(name, description)}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

function filterAndSortPrompts(prompts: PromptModel[] | undefined | null, type: PromptType): PromptModel[] {
  return sortedResource((prompts ?? []).filter((p: any) => (p.type === type)), true);
}

function aggregationActivtyFromType(type: PromptType): string {
  switch (type) {
    case PromptType.manyToOneSummary:
      return "Summary";
    case PromptType.manyToOneDialog:
      return "Dialog";
    case PromptType.manyToOneQuote:
      return "Quote";
    case PromptType.oneToOne:
      return "Transformation";
    }
}

export const AggregationDialog = (props: Props) => {
  const { open, type, ids, onClose, project_id, dataset_id, filename } = props;
  const [result, setResult] = useState<string | undefined>();
  const [error, setError] = useState<Error | undefined>();
  const [modelDefaults, setModelDefaults] = useState<LangTextSummaryAvailableOptions | undefined>();
  const navigate = useNavigate();
  const [options, setOptions] = useState<LangTextAggregateOptions | undefined>();
  
  // NOTE: The options are updated for each change. Since the summary requires an API call, we use a special options-state to throttle the updates
  const [summaryOptions, setSummaryOptions ] = useState<LangTextAggregateOptions | undefined>(undefined);
  const setSummaryOptionsDebounced = useMemo(() => createDebouncer(
    (opts: LangTextAggregateOptions) => { 
      setSummaryOptions(opts); 
    }, 1000), []);
    
  const [loading, setLoading] = useState(false);
  const [currentIds, setCurrentIds] = useState<Set<string>>(new Set(ids));
  const project = useRouteLoaderData('project') as ProjectModel;
  const [prompts, setPrompts] = useState<PromptModel[]>(filterAndSortPrompts(project.prompts, type));
  const [ savePromptData, setSavePromptData ] = useState<[PromptModel | undefined, string] | undefined>();
  
  function onGenerate() {
    setLoading(true);
    setError(undefined);
    Apis.shared()
      .lang.generateSummary(project_id, dataset_id, filename, ids, options!)
      .then(({ text }) => {
        setResult(text);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setError(error);
      });
  }

  const onSavePromptPreset = (name: string, description: string) => {
    const asyncFn = async () => {
      await Apis.shared().project.createPrompt(project.id!, {
        name, description, type, text: savePromptData![1]
      });
      const result = await Apis.shared().project.fetchPrompts(project.id!);
      setPrompts(filterAndSortPrompts(result.items, type));
      setSavePromptData(undefined);
    } 
    asyncFn();
  }

  useEffect(() => {
    if (!modelDefaults) {
      Apis.shared().lang.summaryOptions()
        .then((availableOptions) => {
          const defaultOptions = {...availableOptions.defaults };
          if (prompts.length > 0) {
            defaultOptions.prompt = prompts[0].text;
          }
          setModelDefaults(availableOptions);
          setOptions(defaultOptions);
          setSummaryOptions(defaultOptions);
        })
        .catch(setError);
    }
  }, [modelDefaults, prompts]);

  // Manage caching of old summary to save some bucks on OpenAI

  useEffect(() => {
    const newIds = new Set(ids);
    if (newIds.size !== currentIds.size || newIds.difference(currentIds).size !== 0) {
      setCurrentIds(new Set(ids));
    }
  }, [ids, currentIds]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth PaperProps={{ component: "form" }}>
      <DialogTitle>
        {loading ? "Generating" : "Generate"} a {aggregationActivtyFromType(type)} of {ids.length} Texts
      </DialogTitle>
      <DialogContent>
        <Stack sx={{ mt: 2, width: "100%" }} direction="column">
          {loading ? (
            <CircularProgress sx={{ alignSelf: "center" }} />
          ) : (
            <Box>
              {
                // -------- PROMPT --------
                !!options && (
                  <ModelOptions 
                    prompts={prompts} 
                    options={options} 
                    sx={{mb: 2}} 
                    onManage={() => navigate("/" + promptPath(project.id!))}
                    onChange={(opts: LangTextAggregateOptions) => {
                      setSummaryOptionsDebounced(opts);
                      setOptions(opts);
                    }} 
                    onSavePrompt={(original, text) => setSavePromptData([original, text])}
                    availableOptions={modelDefaults!} 
                    promptError={isPromptError(error)} 
                    />)
              }
              {!result && !error && (
                  // -------- SUMMARY BEFORE SUBMIT -------- TODO: throttle
                  <Box>
                    <Typography variant="subtitle1">You have selected {ids.length} texts</Typography>
                    {open && summaryOptions && <SummaryStats {...{ ids, dataset_id, project_id, filename, options: summaryOptions }} />}
                  </Box>
                )
              }
              {result && (
                // -------- RESULT --------
                <Box>
                  <Divider sx={{my: 2}} />
                  <TextField value={result} multiline label={`Summary of ${ids.length} selected texts`} fullWidth />
                </Box>
              )}
              { error && (
                  // -------- ERROR --------
                  <Box sx={{mt: 2}}>
                    <Typography variant="body1" color="error">
                      Generation failed. {
                        (isPromptError(error)) ? 
                        ("Prompt error - make sure you have the {text} placeholder included in the prompt.")
                        : (error + "")
                      }
                    </Typography>
                  </Box>
                )}
            </Box>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onGenerate} disabled={loading && !!options}>
          {result ? "Regenerate" : "Generate"}
        </Button>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
      {savePromptData !== undefined && (
      <SavePromptDialog 
        open={savePromptData !== undefined} 
        original={savePromptData && savePromptData[0]} 
        text={(savePromptData && savePromptData[1]) ?? ""} 
        onClose={() => setSavePromptData(undefined)}
        onSave={onSavePromptPreset}
        />
      )}
    </Dialog>
  );
};
