import { useEffect } from "react";
import { NavigateOptions, useNavigate, useRouteError } from "react-router-dom";
import { NotAuthenticatedError } from "../components/auth/auth";

function getPathAndQuery(url: string): string {
  const u = new URL(url);
  return u.pathname + "?" + u.searchParams.toString();
}

export default function ErrorPage() {
  const error = useRouteError() as any;
  const navigate = useNavigate();

  useEffect(() => {
    if (error instanceof NotAuthenticatedError) {
      let redirectUrl = error.url ? getPathAndQuery(error.url) : undefined;
      const options:NavigateOptions = { state: { redirectUrl }};
      navigate("/login", options);
    }
  }, []);

  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
  );
}