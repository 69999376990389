import { Stack } from "@mui/material";
import { CustomChip } from "../../../../components/common/Tag";

export type TopicChartProps = {
  rows: [string, number][];
  numRows: number;
};

export function TopicChart(props: TopicChartProps) {
  const { rows, numRows } = props;
  const nRows = rows.slice(0, Math.min(rows.length, numRows)).filter(([,count]) => (count > 0));
  const maxValue = nRows.reduce((p: number, [id, value]) => Math.max(value, p), 0);
  return (
    <Stack direction="row" sx={{ minWidth: "100%" }}>
      <Stack direction="column" alignItems="flex-end">
        {nRows.map(([id]) => (
          <CustomChip key={id} label={id} />
        ))}
      </Stack>
      <Stack direction="column" alignItems="flex-start" sx={{ width: "100%" }}>
        {nRows.map(([id, count]) => (
          <CustomChip key={id} label={count + ""} sx={{ minWidth: `${(100 * count) / maxValue}%` }} />
        ))}
      </Stack>
    </Stack>
  );
}
