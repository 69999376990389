import React from "react";

type SignedLinkFn = (url: string) => Promise<string>;

export function createDownloadHandler(filename: string, downloadUrl: string, signedLinkFn?: SignedLinkFn): (e: React.MouseEvent<HTMLElement>) => Promise<void> {
  return async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const link = document.createElement("a");
    link.download = filename!;
    let url = signedLinkFn ? await signedLinkFn(downloadUrl ?? "") : downloadUrl;
    link.href = url;
    link.click();
  };
}
