import { Box, Grid, Stack, TextField } from "@mui/material";
import { NumericDropdown } from "../common/inputs/NumericDropdown";
import { BooleanDropdown } from "../common/inputs/BooleanDropdown";

type LDAProcessorProps = {
  className?: string;
  disabled?: boolean;
  config: string | undefined;
  cols?: number[];
  onChange: (value: string) => void;
};

type StandardLDAConfig = {
  preprocessing: {
    separator: string;
    filter_extremes_enabled: boolean;
    filter_extremes_n_below: number;
    filter_extremes_n_above: number;
    filter_extremes_keep_n: number;
  };
  topic_model: { num_topics: number; min_rows_per_topic?: number, passes: number };
  subtopic_model: { num_topics: number; min_rows_per_topic?: number, passes: number };
  seed: number;
};

// FIXME: This is a temporary hack - we should figure out a general way to handle processors

export function ldaConfigToVersionName(config: string) {
  try {
    const c = JSON.parse(config) as StandardLDAConfig;
    return `${c.topic_model.num_topics}:${c.subtopic_model.num_topics} themes, ${c.topic_model.passes} passes`;
  } catch {
    return "";
  }
}

export function ldaConfigToDescription(config: string) {
  try {
    const c = JSON.parse(config) as StandardLDAConfig;
    const main = `${c.topic_model.num_topics} Main theme${c.topic_model.num_topics > 1 ? 's' : ''}` + ((c.topic_model.min_rows_per_topic ?? 0) > 0  ? `, ${c.topic_model.min_rows_per_topic} min rows per theme.` : ".");
    const sub = `${c.subtopic_model.num_topics} Sub theme${c.subtopic_model.num_topics > 1 ? 's' : ''}` + ((c.subtopic_model.min_rows_per_topic ?? 0) > 0  ? `, ${c.subtopic_model.min_rows_per_topic} min rows per sub theme.` : ".");
    const passes = `${c.topic_model.passes} passes.`;
    const filter = c.preprocessing.filter_extremes_enabled ? `Filter extremes.` : "";    
    const seed = `Seed: ${c.seed}`;
    return [main, sub, passes, filter, seed].join(" ");
  } catch {
    return "";
  }
}


const defaultLDAConfig: StandardLDAConfig = {
  preprocessing: {
    filter_extremes_enabled: true,
    filter_extremes_n_below: 2,
    filter_extremes_n_above: 0.3,
    filter_extremes_keep_n: 100000,
    separator: ",",
  },
  topic_model: {
    num_topics: 5,
    passes: 100,
  },
  subtopic_model: {
    num_topics: 3,
    passes: 100,
  },
  seed: 3301,
};

export default function LDAProcessorParameters(props: LDAProcessorProps) {
  const { onChange, disabled, cols } = props;
  const config = (props.config && props.config !== "{}") ? (JSON.parse(props.config) as StandardLDAConfig) : defaultLDAConfig;
  const components = [
    <NumericDropdown
      id={"num_topics"}
      key={"num_topics"}
      value={config.topic_model.num_topics}
      minMax={[1, 30]}
      label={"Topics"}
      disabled={disabled}
      onChange={handleChange}
    />,
    <NumericDropdown
      id={"min_rows_per_topic"}
      key={"min_rows_per_topic"}
      value={config.topic_model.min_rows_per_topic ?? NaN}
      items={[10, 15, 20, 30, 50, 100, 150, 200, 250, 300]}
      label={"Rows / topic"}
      disabled={disabled}
      allowEmpty={true}
      onChange={handleChange}
    />,    
    <NumericDropdown
      id={"num_subtopics"}
      key={"num_subtopics"}
      value={config.subtopic_model.num_topics}
      minMax={[1, 10]}
      label={"Subtopics"}
      disabled={disabled}
      onChange={handleChange}
    />,
    <NumericDropdown
      id={"min_rows_per_subtopic"}
      key={"min_rows_per_subtopic"}
      value={config.subtopic_model.min_rows_per_topic ?? NaN}
      items={[10, 15, 20, 30, 50, 100, 150, 200, 250, 300]}
      label={"Rows / subtopic"}
      allowEmpty={true}
      disabled={disabled}
      onChange={handleChange}
    />,        
    <NumericDropdown
      id={"passes"}
      key={"passes"}
      value={config.topic_model.passes}
      items={[10, 20, 50, 75, 100, 125, 150, 200, 250, 300]}
      label={"Passes"}
      disabled={disabled}
      onChange={handleChange}
    />,
    <BooleanDropdown id="filter" key="filter" disabled={disabled} value={config.preprocessing.filter_extremes_enabled} trueLabel={"On"} falseLabel={"Off"} label={"Filter"} onChange={handleChange} />,
    <TextField
      id="seed"
      key={"seed"}
      label="Seed"
      disabled={disabled}
      value={config.seed ?? ""}
      onChange={(e) => handleChange("seed", parseInt(e.target.value))}
      variant="filled"
    />,
  ];

  function handleChange(id: string, value: any) {
    let newConfig: StandardLDAConfig = config;
    switch (id) {
      case "num_topics":
        newConfig = { ...config, topic_model: { ...config.topic_model, num_topics: value } };
        break;
      case "min_rows_per_topic":
        newConfig = { ...config, topic_model: { ...config.topic_model, min_rows_per_topic: value } };
        break;  
      case "num_subtopics":
        newConfig = { ...config, subtopic_model: { ...config.subtopic_model, num_topics: value } };
        break;
      case "min_rows_per_subtopic":
        newConfig = { ...config, subtopic_model: { ...config.subtopic_model, min_rows_per_topic: value } };
        break;
      case "passes":
        newConfig = {
          ...config,
          topic_model: { ...config.topic_model, passes: value },
          subtopic_model: { ...config.subtopic_model, passes: value },
        };
        break;
      case "filter":
        newConfig = {
          ...config,
          preprocessing: {...config.preprocessing, filter_extremes_enabled: value}
        };
        break;
      case "seed":
        console.assert(!isNaN(value));
        newConfig = { ...config, seed: isNaN(value) ? 3301 : value };
        break;
    }
    onChange(JSON.stringify(newConfig));
  }

  function colsAt(pos: number, columns: number[]) {
    let colI = 0;
    let colsum = 0;
    for (let i = 0; i < pos; i++) {
      if ((pos - colsum + 1) > columns[colI]) {
        colsum += columns[colI];
        colI = colI < (columns.length - 1) ? colI + 1 : 0;
      }
    }
    return columns[colI];
  }

  return cols ? (
    <Box sx={{ml: 0}}>
      <Grid container rowSpacing={1} columnSpacing={1}>
        {components.map((c, i) => (<Grid item xs={12 / colsAt(i, cols)} key={i}>{c}</Grid>))}
      </Grid>
    </Box>
  ) : (
    <Stack direction="row" sx={{ width: "100%" }} spacing={1}>
      {[...components]}
    </Stack>
  );
}
