import { delayObject } from "../../util/throttling";
import { standardPostOptions } from "../helpers";
import { IAuthApi } from "../types";

export class AuthApi implements IAuthApi {
  private baseUrl: string;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }
  async login(username: string, password: string): Promise<{ token: string; refreshToken: string }> {
    const url = `${this.baseUrl}/login`;
    const options = standardPostOptions({ body: JSON.stringify({ username, password }) });
    const response = await fetch(url, options);
//    await delayObject(response, 4000);
    if (!response.ok) {
      throw new Error("Login failed");
    }
    const data = await response.json();
    return { token: data.token, refreshToken: data.refreshToken };
  }

  async tokenRefresh(token: string, refreshToken: string): Promise<string> {
    const url = `${this.baseUrl}/refresh`;
    const options = standardPostOptions({ body: JSON.stringify({ token, refreshToken }) });
    const response = await fetch(url, options);
//    await delayObject(response, 4000);
    if (!response.ok) {
      throw new Error("Token refresh failed");
    }
    const data = await response.json();
    return data.token;
  }
}