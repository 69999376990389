import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";

type NumericDropdownProps = {
  id: string;
  className?: string;
  value: number;
  minMax?: [number, number];
  items?: (number)[];
  label: string;
  disabled?: boolean;
  allowEmpty?: boolean;
  onChange: (id: string, value: number | undefined) => void;
};

function generateSequenceOptions(min: number, max: number, allowEmpty: boolean = false): [string, number][] {
  let items: [string, number][] = Array.from({ length: max - min }).map((_, i) => ["option_" + min + i, min + i]);
  if (allowEmpty) {
    const emptyItem = ["", NaN] as [string, number];
    items.unshift(emptyItem);
  }
  return items;
}

function generateItemOptions(items: (number)[], allowEmpty: boolean = false): [string, number][] {
  let result: [string, number][] = items.map((i) => i === undefined ? ["option_none", NaN] : ["option_" + i, i]);
  if (allowEmpty) {
    const emptyItem = ["", NaN] as [string, number];
    result.unshift(emptyItem);
  }
  return result;
}

export function NumericDropdown(props: NumericDropdownProps) {
  const { id, className, label, minMax, value, items, onChange, disabled, allowEmpty } = props;
  const strVal = value + "";
  const [min, max] = minMax ?? [];
  return (
    <Box className={className} sx={{ width: "100%" }}>
      <FormControl variant="filled" sx={{ width: "100%" }}>
        <InputLabel id={id + "-label"}>{label}</InputLabel>
        <Select
          labelId={id + "-label"}
          id={id}
          value={strVal}
          disabled={disabled}
          onChange={(e) => onChange(id, e.target.value === "" ? undefined : parseFloat(e.target.value))}
          label={label}
        >
          {(items ? generateItemOptions(items, allowEmpty) : generateSequenceOptions(min!, max!, allowEmpty)).map((option) => (
            <MenuItem key={option[0]} value={option[1] + ""}>
              {isNaN(option[1]) ? ("--") : (option[1]! + "")}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
