import {
  Box,
  Breadcrumbs,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import * as PageInfo from "../../pages/PageInfo";
import { useLoaderData, useNavigate, useRouteLoaderData } from "react-router-dom";
import { StandardCard } from "../../components/common/StandardCard";
import { ProcessorModel, ProjectModel } from "../../api/apimodels";
import { Nav } from "../../components/common/Nav";
import { Apis } from "../../api/apis";
import DataHeaderItem from "../../components/common/DataHeaderItem";

export function ProcessorDetailsPage() {
  const navigate = useNavigate();
  const processor = useLoaderData() as ProcessorModel;
  const project = useRouteLoaderData('project') as ProjectModel;

  const processorDownloadUrl = Apis.shared().project.processorDownloadUrl(project.id ?? "", processor.id ?? "");

  const onDelete = async () => {
    try {
      if (project.id && processor.id) {
        await Apis.shared().project.deleteProcessor(project.id, processor.id);
        navigate("/" + PageInfo.processorPath(project.id!, undefined));
      }
    } catch (e) {
      console.error(e);
    }
  };


  return !processor ? (
    <></>
  ) : (
    <Box sx={{ display: "flex" }}>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          overflow: "auto",
        }}
      >
        <Breadcrumbs aria-label="breadcrumb" sx={{ ml: 3, mt: 5 }}>
          <Nav to="./..">{PageInfo.ProcessorPage.name}</Nav>
          <Typography color="text.primary">{processor.name}</Typography>
        </Breadcrumbs>
        <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
          <Grid container spacing={3} sx={{"& .MuiPaper-root": {mt: 0}}}>
            <Grid item xs={12} md={6} lg={6}>
              <StandardCard>
                <Typography variant="h6" sx={{ pb: 2 }}>
                  General information
                </Typography>
                <DataHeaderItem name={processor.name} description={processor.description} filename={processor.filename} downloadUrl={processorDownloadUrl} onDelete={onDelete} />
              </StandardCard>
            </Grid>
            <Grid item xs={12} md={4} lg={4}></Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}
