import React, { useCallback, useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useLocation, useNavigate } from "react-router-dom";
import { Avatar, Checkbox, FormControlLabel, Paper, TextField, Typography } from "@mui/material";
import Link from "@mui/material/Link";
import { Apis } from "../api/apis";
import { AuthApi } from "../api/impl/auth";
import { sharedApiConfig } from "../config";
import { ApplicationThemeProvider } from "../components/theme";
import { EphemeralStorageProvider, PersistentStorageProvider, Session } from "../components/auth/session";
import { LoaderButton } from "../components/common/LoaderButton";

type LoginFooterProps = {
  forgotPasswordLink?: string;
  signupLink?: string;
};

function LoginFooter(props: LoginFooterProps) {
  const { forgotPasswordLink, signupLink } = props;
  return (
    <Grid container>
      <Grid item xs>
        (
        {forgotPasswordLink && (
          <Link href={forgotPasswordLink} variant="body2">
            Forgot password?
          </Link>
        )}
        )
      </Grid>
      <Grid item>
        (
        {signupLink && (
          <Link href={signupLink} variant="body2">
            {"Don't have an account? Sign Up"}
          </Link>
        )}
        )
      </Grid>
    </Grid>
  );
}

type Props = {
  forgotPasswordLink?: string;
  signupLink?: string;
  canRemember?: boolean;
};

function Main(props: Props) {
  const { forgotPasswordLink, signupLink, canRemember } = props;
  const location = useLocation();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [remember, setRemember] = useState(Session.getDefaultStorageProvider().name === PersistentStorageProvider.name);
  const navigate = useNavigate();

  const { redirectUrl } = location.state ?? {};

  const handleSubmit = useCallback(
    async () => {
      try {
        setLoading(true);
        const authApi = new AuthApi(sharedApiConfig.endpoints.auth);
        const { token, refreshToken } = await authApi.login(username, password);
        Session.shared().set(token, refreshToken);
        Apis.shared().setAuthentication(token, refreshToken);
        
        setLoading(false);
        setError("");
        navigate(redirectUrl ?? "/", { state: { token, refreshToken } });
      } catch (e: any) {
        setLoading(false);
        setError("Please check username and password");
      }
    },
    [navigate, password, username, redirectUrl]
  );

  const toggleRememberMe = () => {
    let providerName: string = EphemeralStorageProvider.name;
    let newRemember = !remember;
    if (newRemember) {
      providerName = PersistentStorageProvider.name;
    } 
    Session.changeStorageProvider(Session.shared(), providerName);
    setRemember(newRemember);
  }

  return (
      <Paper sx={{height: '100%'}}>
        <Box sx={{ display: "flex" }}>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              overflow: "auto",
            }}
          >
            <Container maxWidth="xs">
              <Box
                sx={{
                  marginTop: 8,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                  <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                  Sign in
                </Typography>
                <Box component="form" noValidate sx={{ mt: 1 }}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="username"
                    label="Username"
                    name="username"
                    error={!!error}
                    onChange={(e: any) => {
                      setUsername(e.target.value);
                    }}
                    autoComplete="username"
                    autoFocus
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    error={!!error}
                    onChange={(e: any) => {
                      setPassword(e.target.value);
                    }}
                    autoComplete="current-password"
                  />
                  {error && (
                    <Typography sx={{ mt: 2 }} color="error">
                      {error}
                    </Typography>
                  )}
                  {canRemember && <FormControlLabel onChange={toggleRememberMe} checked={remember} sx={{mt: 2}} control={<Checkbox color="primary" />} label="Remember me" />}
                  <LoaderButton loading={loading} title="Sign In" onClick={handleSubmit} variant="contained" color="primary" />
                  {(forgotPasswordLink || signupLink) && <LoginFooter />}
                </Box>
              </Box>
            </Container>
          </Box>
        </Box>
      </Paper>
  );
}


export function LoginPage() {
  return <ApplicationThemeProvider>
      <Main canRemember={true} />
    </ApplicationThemeProvider>;
}