import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Box, Button, Stack, styled, SxProps, Theme } from "@mui/material";
import { useState } from "react";
import { ExplorationSelection } from "../../../api/apimodels";
import ResourceSelect from "../../../components/common/ResourceSelect";
import { CreateOrEditDialog, DetailsDialog } from "./SelectionDialogs";

export const SelectionMenuButton = styled(Button)(({ theme }) => ({
    paddingTop: '4px'
  }));

type Props = {
    selections: ExplorationSelection[];
    selected?: ExplorationSelection;
    sx?: SxProps<Theme>;
    onPick: (s: ExplorationSelection) => void;
    onCreate: (name: string, description: string, keepFilter: boolean) => void;
    onSave: (s: ExplorationSelection) => void;
    onDelete: (s: ExplorationSelection) => void;
}

export function SelectionPanel(props: Props) {
    const { sx, selected, selections, onCreate, onPick, onSave, onDelete } = props;
    const [createNewOpen, setCreateNewOpen] = useState(false);
    const [showDetails, setShowDetails] = useState(false);

    function onResourceSelected(s: ExplorationSelection) {
        onPick(s);
    }

    function onShowDetails() {
      setShowDetails(true);
    }

    async function onClickSave() {
      await onSaveMetadata(selected);
    }

    async function onSaveMetadata(newSelection: ExplorationSelection | undefined) {
      if (newSelection) {
        setShowDetails(false);
        onSave(newSelection);
      }
    }

    function onOpenCreateDialog() {
        setCreateNewOpen(true);
    }

    function onCreateDialogComplete(name: string, description: string, keepFilter: boolean) {
      setCreateNewOpen(false);
      onCreate(name, description, keepFilter);
    }

    async function onDeleteSelection() {
      if (selected) {
        onDelete(selected);
        setShowDetails(false);
      }
    }

    return (
        <Box sx={sx}>
            <Stack direction="column" spacing={1} sx={{ pb: 1 }}>
              {/*}
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="h6" sx={{ pb: 1 }}>
                        Saved selections
                    </Typography>
                </Stack>
                {*/}
                <ResourceSelect
                    className="resource-select"
                    label="Selection"
                    disabled={selections.length === 0}
                    selected={selected}
                    variant="standard"
                    onChange={(s: ExplorationSelection) => onResourceSelected(s)}
                    resources={selections}
                />
                <Stack direction="row" justifyContent="space-between" spacing={1} sx={{ pt: 1 }}>
                    <SelectionMenuButton color="primary" variant="outlined" size="small" onClick={onShowDetails} disabled={selections.length === 0}><MoreHorizIcon /></SelectionMenuButton>
                    <Stack direction="row" spacing={1}>
                        <SelectionMenuButton color="primary" variant="outlined" size="small" onClick={onClickSave} disabled={selections.length === 0}>Save</SelectionMenuButton>
                        <SelectionMenuButton color="primary" variant="outlined" size="small" onClick={onOpenCreateDialog}>New</SelectionMenuButton>
                    </Stack>
                </Stack>
            </Stack>
            <CreateOrEditDialog
                open={createNewOpen}
                dialogTitle="New Selection"
                submitButtonTitle="Create"
                onClose={() => { setCreateNewOpen(false); }}
                onSubmit={onCreateDialogComplete}
            />
            {selected && (
            <DetailsDialog
                open={showDetails}
                dialogTitle={selected.name}
                name={selected.name}
                description={selected.description}
                onClose={() => { setShowDetails(false); }}
                onSave={(name, description) => (onSaveMetadata({...selected, name, description} as ExplorationSelection))}
                onDelete={onDeleteSelection}
            />
            )}
        </Box>
    )
}