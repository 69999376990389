import { Box, Button, Divider, Stack, SxProps, Theme, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { DatasetModel, DataStatisticsResult, ExplorationFilter } from "../../../api/apimodels";
import { Apis } from "../../../api/apis";
import { StableColorMap } from "../../../util/colormap";
import { AllChartsDialog } from "./AllChartsPanel";
import { ThemeChart } from "./charts/ThemeChart";
import { TopicChart } from "./charts/TopicChart";

type Props = {
  sx?: SxProps<Theme>;
  projectId: number;
  dataset: DatasetModel;
  filter?: ExplorationFilter;
  themeColorFn: StableColorMap;
};

export default function ChartPanel(props: Props) {
  const nTopTopics = 10;
  const { sx, projectId, dataset, filter, themeColorFn } = props;
  const [statistics, setStatistics] = useState<DataStatisticsResult>({ themes: [], topics: [], filtered_rows: 0, total_rows: 0, original_texts: undefined });
  const [ allChartsOpen, setAllChartsOpen ] = useState(false);
  useEffect(() => {
    const loadFn = async () => {
      const stats: DataStatisticsResult = await Apis.shared().data.queryStatistics(
        projectId,
        dataset.id!,
        dataset.filename!,
        filter
      );
      setStatistics(stats);
    };
    loadFn();
  }, [dataset.filename, dataset.id, filter, projectId]);
  return (
    statistics.filtered_rows === 0 ? (<Box>
      <Typography variant="h6" component="h3">No data to show</Typography>
    </Box>) : (
    <Stack direction="column">
      <Stack direction="row" sx={sx} alignItems="center" spacing={2}>
        {statistics.themes && statistics.themes.length > 0 && (
          <Box sx={{ height: "100%" }}>
            <Typography component="h3" variant="subtitle1" sx={{ textAlign: "center", fontSize: '115%' }}>
              Themes
            </Typography>
            <ThemeChart rows={statistics.themes} themeColorFn={themeColorFn} />
          </Box>
        )}
        {statistics.topics && statistics.topics.length > 0 && (
          <Stack sx={{ flexGrow: 1, pr: 2, height: "100%" }} direction="column" justifyContent="space-between">
            <Typography component="h3" variant="subtitle1" sx={{ textAlign: "center", mb: 1, fontSize: '115%' }}>
              {`Top ${nTopTopics} Topics`}
            </Typography>
            <TopicChart rows={statistics.topics} numRows={nTopTopics} />
            <Box />
          </Stack>
        )}
      </Stack>
      <Divider sx={{ mt: 2 }} />
      <Stack direction="row" sx={{ mt: 2, pb: 0 }} justifyContent="space-between" alignItems="center">
        <Typography variant="body1">
          {statistics.filtered_rows} matching texts of {statistics.total_rows}
          {statistics.topics && `, ${statistics.topics.filter(([, count]) => (count > 0)).length} topics`}
        </Typography>
        <Button size="small" onClick={() => setAllChartsOpen(true)}>View all</Button>
      </Stack>
      <AllChartsDialog open={allChartsOpen} filter={filter} projectId={projectId} dataset={dataset} themeColorFn={themeColorFn} title={""} onClose={() => setAllChartsOpen(false)} />
      </Stack>
    )
  );
}
