import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, styled, TextField } from "@mui/material";
import { PromptModel } from "../../api/apimodels";
import { PromptType, promptTypeInfo } from "./PromptTypes";
import { ChangeEvent, useEffect, useState } from "react";
import { PromptTypeSelect } from "./PromptsPage";

const WideDialog = styled(Dialog)(({ theme }) => ({
  "& form.MuiDialog-paper": {
    minWidth: '800px',
    maxWith: '800px',
    [theme.breakpoints.down('md')]: {
      minWidth: '90%',
      maxWith: 'none'
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: '95%',
      maxWith: 'none'
    },
  }
}));

type EditDetailsDialogProps = {
  open: boolean;
  dialogTitle: string;
  prompt: PromptModel;
  onClose: () => void;
  onSave: (name: string, description: string, text: string, type: PromptType) => void;
}

export function EditPromptDialog(props: EditDetailsDialogProps) {
  const { open, onClose, onSave, dialogTitle, prompt } = props;
  const { name, description, type, text } = prompt;
  const [typeId] = promptTypeInfo(type);
  const [currentType, setCurrentType] = useState(typeId as PromptType);
  const [currentText, setCurrentText] = useState(text);
  const [currentName, setCurrentName] = useState(name ?? "");
  const [currentDescription, setCurrentDescription] = useState(description ?? "");
  
  useEffect(() => {
    if (open) {
      setCurrentName(type);
      setCurrentDescription(text);
      setCurrentName(name ?? "");
      setCurrentDescription(description ?? "");
    } else {
      setCurrentName("");
      setCurrentName("");
    }
  }, [open, name, description, type, text]);

  return (
      <WideDialog
          open={open}
          onClose={onClose}
          PaperProps={{
              component: 'form',
              onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                  event.preventDefault();
                  onSave(currentName, currentDescription, currentText, currentType);
              },
          }}
      >
          <DialogTitle>{dialogTitle}</DialogTitle>
          <DialogContent>
              <TextField required margin="dense" id="name" name="name" label="Name" type="text" fullWidth value={currentName} onChange={(e) => { setCurrentName(e.target.value); }} variant="standard" />
              <TextField multiline margin="dense" id="description" name="description" label="Description" type="text" value={currentDescription} onChange={(e) => { setCurrentDescription(e.target.value); }} fullWidth variant="standard" />
              <Divider sx={{mt: 2, mb: 2}} />
              <TextField id="update-resource-prompt-text"
                label="Prompt text"
                variant="filled"
                multiline
                fullWidth
                value={currentText}
                onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => (setCurrentText(e.target.value))}
                sx={{ pb: 2 }}
              />
              <PromptTypeSelect label="Type" type={currentType} onChange={setCurrentType} tooltipPlacement={"right"} />
          </DialogContent>
          <DialogActions>
              <Button onClick={onClose}>Cancel</Button>
              <Button type="submit">Save</Button>
          </DialogActions>
      </WideDialog>
  );
}

type ConfirmDeleteDialogProps = {
  open: boolean;
  onCancel: () => void;
  onDelete: () => void;
};

export const ConfirmDeleteDialog = (props: ConfirmDeleteDialogProps) => {
  const {open, onCancel, onDelete} = props;
  return <Dialog open={open} onClose={onCancel}>
    <DialogTitle>This prompt will be deleted</DialogTitle>
    <DialogContent>This cannot be undone. Do you want to proceed?</DialogContent>
    <DialogActions>
      <Button onClick={onCancel}>Cancel</Button>
      <Button onClick={onDelete} color="error">Delete</Button>
    </DialogActions>
  </Dialog>
}
