import React, { ReactNode, useState } from "react";
import { Box, Card, Chip, Divider, Stack, SxProps, TextField, Theme, Typography } from "@mui/material";
import FileUploadButton from "../common/FileUploadButton";
import { LoaderButton } from "./LoaderButton";

export function CreateFormHeader({ icon, title, sx }: { icon?: any; title: string, sx?: SxProps<Theme> }) {
  return (
    <Stack
      className="resource-create-form-header"
      direction="row"
      alignItems="center"
      spacing={1}
      sx={{
        ...sx,
        "& .MuiSvgIcon-root": { lineHeight: "18px" },
      }}
    >
      {!!icon && icon}
      <Typography variant="h6">{title}</Typography>
    </Stack>
  );
}

type Props = {
  embedded?: boolean;
  title?: string;
  icon?: any;
  resourceNameLabel: string;
  resourceDescriptionLabel: string;
  defaultName?: string;
  defaultDescription?: string;
  uploadButtonLabel?: string;
  createButtonLabel: string;
  noBottomDivider?: boolean;
  children?: ReactNode;
  childrenPlacement?: "pre" | "post" | undefined;
  error?: Error;
  requireDescription?: boolean;
  onCreate: (name: string, description: string, filename?: string, data?: ArrayBuffer) => Promise<void>;
  canCreate?: () => boolean;
};

function FormContent(props: Props) {
  const { createButtonLabel, uploadButtonLabel, resourceNameLabel, resourceDescriptionLabel, canCreate, requireDescription, defaultName, defaultDescription, childrenPlacement, noBottomDivider } = props;
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [resourceData, setResourceData] = useState<ArrayBuffer | undefined>();
  const [hasCustomName, setHasCustomName] = useState(false);
  const [hasCustomDescription, setHasCustomDescription] = useState(false);
  const [filename, setFilename] = useState("");
  const [loading, setLoading] = useState(false);
  const hasUploadButton = !! uploadButtonLabel

  function getNameOrDefault() {
    return hasCustomName ? name : (defaultName ?? "");
  }

  function getDescriptionOrDefault() {
    return hasCustomDescription ? description : (defaultDescription ?? "");
  }

  function createButtonDisabled() {
    return (getNameOrDefault()?.length === 0) || 
      (requireDescription && (getDescriptionOrDefault()?.length === 0)) || 
      (hasUploadButton && (!resourceData)) || 
      (canCreate ? !canCreate() : false)
  }

  async function onSubmit() {
    if ((!resourceData) && hasUploadButton) {
      return;
    }
    setLoading(true);
    try {
      await props.onCreate(
        getNameOrDefault(),
        getDescriptionOrDefault(),
        hasUploadButton ? filename : undefined,
        hasUploadButton ? resourceData : undefined
      );
      setName("");
      setDescription("");
      setFilename("");
      setResourceData(undefined);
    } catch (e) {
      console.warn("Unable to create resource", e);
    }
    setLoading(false);
  }

  async function onUpload(e: React.ChangeEvent<HTMLInputElement>) {
    const { target } = e;
    setLoading(true);
    const fileReader = new FileReader();
    if ((target.files?.length ?? 0) > 0) {
      const file = target.files![0];
      fileReader.onload = (e) => {
        const result = e.target?.result;
        if (result) {
          setResourceData(result as ArrayBuffer);
          setFilename(file.name);
          if (name.length === 0) {
            setName(file.name);
          }
          if (description.length === 0) {
            setDescription("Uploaded at " + (new Date()).toLocaleDateString());
          }
        }
        setLoading(false);
      };
      fileReader.readAsArrayBuffer(file);
    }
  }

  
  function onChangeName(event: React.ChangeEvent<HTMLInputElement>) {  
    setName(event.target.value);
    setHasCustomName(true);
  }

  function onChangeDescription(event: React.ChangeEvent<HTMLInputElement>) {  
    setDescription(event.target.value);
    setHasCustomDescription(true);
  }

  function onNameBlur(event: React.FocusEvent<HTMLInputElement>) {  
    setHasCustomName(!!name && name !== defaultName);
  }

  function onDescriptionBlur(event: React.FocusEvent<HTMLInputElement>) {
    setHasCustomDescription(!!description && description !== defaultDescription);
  }

  function onClearFile() {
    setFilename("");
    setResourceData(undefined);
  }

  return (
    <Box
      component="form"
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        "& .resource-create-form-header": { mt: 0, mb: 1, width: "100%" },
        "& .MuiTextField-root": { mb: 1, width: "100%" },
        "& .upload-button": { mt: 1 },
        "& .file-chip": { mt: 1 },
        "& .create-button": { mt: 1, alignSelf: "flex-end", mb: 0 },
      }}
      noValidate
      autoComplete="off"
    >
      {props.title && <CreateFormHeader title={props.title} icon={props.icon} />}
      {(childrenPlacement === 'pre' || childrenPlacement === undefined) && props.children}
      <TextField
        id="create-resource-name"
        label={resourceNameLabel}
        variant="filled"
        value={getNameOrDefault()}
        onChange={onChangeName}
        onBlur={onNameBlur}
      />
      <TextField
        id="create-resource-description"
        label={resourceDescriptionLabel}
        multiline
        rows={2}
        variant="filled"
        value={getDescriptionOrDefault()}
        onChange={onChangeDescription}
        onBlur={onDescriptionBlur}
      />
      {childrenPlacement === 'post' && props.children}
      {hasUploadButton && (
        <Box>
          {!resourceData ? (
            <FileUploadButton onPick={onUpload} label={uploadButtonLabel} disabled={loading} />
          ) : (
            <Chip className="file-chip" label={filename} onDelete={onClearFile} />
          )}
        </Box>
      )}
      {!noBottomDivider && (<Divider sx={{ mt: 2, mb: 2 }} />)}
      {props.error && (
        <Box sx={{ mb: 1 }}>
          <Typography variant="body1" color="error" sx={{'whiteSpace': 'pre-line', mr: 1}}>
            {props.error.message}
          </Typography>
        </Box>
      )}
      <LoaderButton
        className="create-button"
        onClick={onSubmit}
        variant="outlined"
        color="secondary"
        title={createButtonLabel}
        disabled={createButtonDisabled()}
        loading={loading}
      />
    </Box>
  );
}

function ResourceCreateForm(props: Props) {
  return props.embedded ? (
    <FormContent {...props} />
  ) : (
    <Card sx={{ p: 2 }}>
      <FormContent {...props} />
    </Card>
  );
}

export default ResourceCreateForm;
