import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, Button, ListItemIcon, ListItemText, Menu, MenuItem, Stack, SxProps, TextField, TextFieldPropsSizeOverrides, Theme, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from "@mui/material";
import { OverridableStringUnion } from '@mui/types';
import { ChangeEvent, FC, FocusEventHandler, useCallback, useRef, useState } from "react";
import { LangTextAggregateOptions, LangTextSummaryAvailableOptions, PromptModel } from "../../../../api/apimodels";
import { PromptPage as PromptPageInfo } from "../../../PageInfo";
import { promptTypeInfo } from "../../../prompt/PromptTypes";

export type PromptSelectProps = {
  prompts: PromptModel[];
  label?: string;
  onChange: (prompt: PromptModel) => void;
  tooltipPlacement?: "left" | "right" | "top" | "bottom";
  sx?: SxProps<Theme>;
};
const PromptSelect: FC<PromptSelectProps> = ({prompts, label, onChange, tooltipPlacement, sx}) => {
  const [open, setOpen] = useState(false);
  const ref = useRef(null);

  const renderItem = (p: PromptModel) => {
    return <Stack sx={{ width: "100%" }} direction="row" alignItems="center">
    <ListItemIcon>{promptTypeInfo(p.type)[1]}</ListItemIcon>
    <ListItemText>{p.name}</ListItemText>
  </Stack>
  }
  
  return (
    <Box>
      {label 
        ? (<Button color="inherit" ref={ref} variant="contained" size="small" endIcon={PromptPageInfo.menuIcon} onClick={() => setOpen(!open)}>{label}</Button>) 
        : (<Button color="inherit" ref={ref} variant="contained" size="small" onClick={() => setOpen(!open)}>{PromptPageInfo.menuIcon}</Button>)}      
      <Menu open={open} anchorEl={ref.current} anchorReference="anchorEl" onClose={() => (setOpen(false))}>
        {prompts.map((p) => (
          <MenuItem value={p.id} key={p.id} onClick={() => {onChange(p); setOpen(false); }}>
            {!!tooltipPlacement ? (
              <Tooltip title={p.description} placement={tooltipPlacement}>
              {renderItem(p)}
            </Tooltip>
            ) : (renderItem(p))}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}

type DecimalTextFieldProps = {
  sigFigs: number;
  value: number;
  label?: string;
  size?: OverridableStringUnion<"small" | "medium", TextFieldPropsSizeOverrides> | undefined;
  sx?: SxProps<Theme>;
  onChange: (value: number) => void;
}

const DecimalTextField: FC<DecimalTextFieldProps> = ({ value, onChange, sigFigs, size, sx, label }) => {
  const [currentValue, setCurrentValue] = useState(`${value}`);

  function onElmtChange(e: ChangeEvent<HTMLInputElement>) {
    setCurrentValue(e.target.value);
  }

  const onElmtBlur: FocusEventHandler<HTMLInputElement> = (event) => {
    const precision = Math.pow(10, sigFigs);
    const roundedValue = Math.round(parseFloat(currentValue) * precision) / precision;
    setCurrentValue(roundedValue + "");
    onChange(roundedValue);
  };

  return <TextField label={label} value={currentValue} onChange={onElmtChange} onBlur={onElmtBlur} size={size} sx={{...sx, "& .MuiInputBase-input": { textAlign: 'center'}}} />;
};

type ModelOptionsProps = {
  options: LangTextAggregateOptions;
  availableOptions: LangTextSummaryAvailableOptions;
  sx?: SxProps<Theme>;
  promptError?: boolean;
  prompts: PromptModel[];
  onManage: () => void;
  onChange: (newOptions: LangTextAggregateOptions) => void;
  onSavePrompt: (original: PromptModel | undefined, text: string) => void;
};



export const ModelOptions = (props: ModelOptionsProps) => {
  const { options, prompts, sx, onSavePrompt, onChange, onManage, availableOptions, promptError } = props;
  const [ text, setText ] = useState(options.prompt ?? "");
  const [ promptPreset, setPromptPreset ] = useState<PromptModel | undefined>(undefined);

  const onChangeModelName = (event: any, name: string) => {
    onChange({ ...options, model: name });
  };

  const onTemperatureChange = (value: number) => {
    onChange({ ...options, temperature: value });
  };

  const onPromptChange = (e: ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value;
    setText(text);
    onChange({ ...options, prompt: text });
  };

  const onPickPresetPrompt = (p: PromptModel) => {
    setPromptPreset(p);
    onChange({ ...options, prompt: p.text });
  }

  const onClickSavePrompt = useCallback(() => {
    onSavePrompt(promptPreset, text);
  }, [onSavePrompt, promptPreset, text]);

  return (
    <Box sx={sx}>
      <Stack direction="row" justifyContent="flex-end" alignItems="end" sx={{mb: 2}} gap={1}>
        <Typography flexGrow={1} variant="subtitle1">Prompt</Typography>
        <Tooltip title="Go to prompts page">
          <Button color="inherit" size="small" variant="text" onClick={onManage} endIcon={<ArrowForwardIcon />}>Manage</Button>
        </Tooltip>
        <Button color="inherit" size="small" variant="contained" endIcon={PromptPageInfo.menuIcon} onClick={onClickSavePrompt}>Save</Button>
        <PromptSelect label="Load" prompts={prompts} onChange={onPickPresetPrompt} sx={{minWidth: '100%'}} />
      </Stack>
      <TextField multiline fullWidth value={options?.prompt ?? ""} onChange={onPromptChange} error={promptError} />
      <Stack direction="row" justifyContent="space-between" sx={{mt: 2}}>
        <ToggleButtonGroup color="primary" value={options.model} size="small" exclusive onChange={onChangeModelName}>
          {availableOptions.models.map((n) => (
            <ToggleButton key={n} value={n}>{n}</ToggleButton>
          ))}
        </ToggleButtonGroup>
        <DecimalTextField
          label="Temperature"
          value={options.temperature}
          onChange={onTemperatureChange}
          sigFigs={4}
          size="small"
          sx={{ maxWidth: "6rem" }}
        />
      </Stack>
    </Box>
  );
};