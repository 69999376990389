import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";

type BooleanDropdownProps = {
  id: string;
  className?: string;
  value: boolean;
  trueLabel: string;
  falseLabel: string;
  label: string;
  disabled?: boolean;
  onChange: (id: string, value: boolean) => void;
};

export function BooleanDropdown(props: BooleanDropdownProps) {
  const { id, className, label, value, onChange, trueLabel, falseLabel, disabled } = props;
  return (
    <Box className={className} sx={{ width: "100%" }}>
      <FormControl variant="filled" sx={{ width: "100%" }}>
        <InputLabel id={id + "-label"}>{label}</InputLabel>
        <Select
          labelId={id + "-label"}
          id={id}
          value={value ? "1" : "0"}
          disabled={disabled}
          onChange={(e) => onChange(id, parseInt(e.target.value as any) === 1)}
          label={label}
        >
          <MenuItem key={"true"} value={"1"}>
            {trueLabel}
          </MenuItem>
          <MenuItem key={"false"} value={"0"}>
            {falseLabel}
          </MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}
