import { ThemeOptions, ThemeProvider, colors, createTheme } from "@mui/material";
import { ReactNode } from "react";

declare module '@mui/material/styles' {
  interface Palette {
    topic: Palette['primary'];
    subtopic: Palette['primary'];
    overlay: string;
    modalDark: string;
  }

  interface PaletteOptions {
    topic?: PaletteOptions['primary'];
    subtopic?: PaletteOptions['primary'];
    overlay: string;
    modalDark: string;
  }
}

function getSystemTheme(): ThemeOptions {
  const darkThemeMq = window.matchMedia("(prefers-color-scheme: dark)");
  const sharedOptions: ThemeOptions = {
    typography: {
      h6: {
        fontWeight: 400,
        letterSpacing: "0.015em",
        fontSmooth: "antialiased",
        textRendering: "optimizeLegibility",
      },
    },
  };
  if (darkThemeMq.matches) {
    return {
      palette: {
        mode: "dark",
        subtopic: {
          main: colors.yellow[400],
          dark: colors.yellow[700],
          light: colors.yellow[200],
          contrastText: colors.yellow[100]
        },
        topic: {
          main: colors.deepOrange[400],
          dark: colors.deepOrange[700],
          light: colors.deepOrange[200],
          contrastText: colors.deepOrange[100]
        },
        overlay: "rgba(32, 32, 32, 0.5)",
        modalDark: "rgb(24, 24, 24)"
      },
      ...sharedOptions,
    };
  }
  return {
    palette: {
      mode: "light",
      topic: {
        main: colors.pink[400],
        dark: colors.pink[700],
        light: colors.pink[300],
        contrastText: colors.pink[100]
      },
      subtopic: {
        main: colors.orange[400],
        dark: colors.orange[700],
        light: colors.orange[300],
        contrastText: colors.orange[100]
      },
      overlay: "rgba(224, 224, 224, 0.5)",
      modalDark: "rgb(232, 232, 232)"
    },
    ...sharedOptions,
  };
}

export function ApplicationThemeProvider({children}: {children: ReactNode}) {
  const defaultTheme = createTheme(getSystemTheme());
  return <ThemeProvider theme={defaultTheme}>{children}</ThemeProvider>
}