import FolderIcon from "@mui/icons-material/Folder";
import {
  Avatar,
  CircularProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { ProjectModel } from "../../api/apimodels";
import { ApiLoadingState } from "../../api/types";
import { useOrganisation } from "../../state/organisationstate";

type Props = {
  projects: ProjectModel[];
  onSelectProject: (id: number) => void;
};

function ProjectList(props: Props) {
  const { projects } = props;
  const { loadingState } = useOrganisation();

  return (
    <Paper
      sx={{
        p: 2,
        display: "flex",
        flexDirection: "column",
        "& .MuiTypography-root.title": { marginTop: 0, marginBottom: 2, width: "100%" },
        "& .MuiListItem-root": { paddingLeft: 0, alignSelf: "flex-end" },
        "& .MuiListItemText-secondary": { color: "inherit" },
      }}
    >
        <Typography className="title" variant="h6">
          All projects
        </Typography>
      <List dense={false}>
        {loadingState === ApiLoadingState.loading ? (
          <CircularProgress />
        ) : (
          <>
            {projects
              .map((p) => ({ p, color: undefined }))
              .map(({ p, color }) => (
                <ListItem
                  key={p.id}
                >
                  <ListItemButton onClick={() => props.onSelectProject(p.id!)}>
                    <ListItemAvatar>
                      <Avatar sx={{ bgcolor: color }}>
                        <FolderIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText sx={{ color }} primary={p.name} secondary={p.subtitle} />
                  </ListItemButton>
                </ListItem>
              ))}
          </>
        )}
      </List>
    </Paper>
  );
}

export default ProjectList;
