import { ApiGeneralError, ApiNotAuthorizedError, ApiNotFoundError, FetchFn, SignedLinkFn } from "./types";

export function createAuthFetchFn(token: string): FetchFn {
  return (input, init) => {
    const options = { ...init };
    let headers: any = { ...options.headers } ?? {};
    headers["Authorization"] = `Bearer ${token}`;
    options.headers = headers;
    return fetch(input, options);
  };
}

export function createSignedLinkFn(token: string): SignedLinkFn {
  return async (url: string) => {
    let u = new URL(url);
    u.searchParams.set("t", token);
    return u.toString();
  }
}

export function standardGetOptions(additionalOptions: RequestInit = {}): RequestInit {
  let opts: any = {
    method: "GET",
    //mode: 'no-cors',
    redirect: "follow",
  };
  Object.keys(additionalOptions).forEach((k: string) => {
    opts[k] = (additionalOptions as any)[k];
  });
  return opts;
}

export function standardPostOptions(additionalOptions: RequestInit = {}): RequestInit {
  let opts: any = {
    method: "POST",
    //mode: 'no-cors',
    redirect: "follow",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
    },
  };
  Object.keys(additionalOptions).forEach((k: string) => {
    opts[k] = (additionalOptions as any)[k];
  });
  return opts;
}

export function standardPutOptions(additionalOptions: RequestInit = {}): RequestInit {
  let opts: any = {
    method: "PUT",
    //mode: 'no-cors',
    redirect: "follow",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
    },
  };
  Object.keys(additionalOptions).forEach((k: string) => {
    opts[k] = (additionalOptions as any)[k];
  });
  return opts;
}

export function standardDeleteOptions(additionalOptions: RequestInit = {}): RequestInit {
  let opts: any = {
    method: "DELETE",
    //mode: 'no-cors',
    redirect: "follow",
    cache: "no-cache",
  };
  Object.keys(additionalOptions).forEach((k: string) => {
    opts[k] = (additionalOptions as any)[k];
  });
  return opts;
}

export function throwApiError(status: number, details?: Record<string, any>) {
  switch (status) {
    case 401:
    case 403:
      throw new ApiNotAuthorizedError(status, details);
    case 404:
      throw new ApiNotFoundError(status, details);
    default:
      break;
  }
  if (status >= 400) {
    throw new ApiGeneralError(status, details);
  }
}