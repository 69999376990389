import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Navigate, RouterProvider, createBrowserRouter, json } from "react-router-dom";
import ErrorPage from "./pages/ErrorPage";
import OverviewPage from "./pages/OverviewPage";
import ProjectPage from "./pages/project/ProjectPage";
import DatasetsPage from "./pages/dataset/DatasetPage";
import ProcessorsPage from "./pages/processor/ProcessorsPage";
import ExplorationPage from "./pages/explorations/ExplorationsPage";
import PromptsPage from "./pages/prompt/PromptsPage";
import { LoginPage } from "./pages/LoginPage";
import { DatasetDetailsPage } from "./pages/dataset/DatasetDetailsPage";
import { ProcessorDetailsPage } from "./pages/processor/ProcessorDetailsPage";
import { Session } from "./components/auth/session";
import { Apis } from "./api/apis";
import { sharedApiConfig } from "./config";
import { ExplorationDetailsPage } from "./pages/explorations/ExplorationsDetailsPage";
import { PromptDetailsPage } from "./pages/prompt/PromptDetailsPage";

Session.createShared(Session.getDefaultStorageProvider());
Apis.createShared(sharedApiConfig, Session.shared().token, Session.shared().refreshToken);

const router = createBrowserRouter([
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/",
    id: "projects",
    element: <App />,
    loader: async () => json(await Apis.shared().metadata.fetchProjects()),
    errorElement: <ErrorPage />,
    children: [
      {
        path: "overview",
        element: <OverviewPage />,
      },
      {
        path: "project/:projectId",
        id: "project",
        loader: async ({ params }) => {
          return json(await Apis.shared().project.fetchProject(params.projectId!));
        },
        children: [
          {
            element: <ProjectPage />,
            index: true,
          },
          {
            path: "dataset",
            element: <DatasetsPage />,
            loader: async ({ params }) => json(await Apis.shared().project.fetchDatasets(params.projectId!)),
          },
          {
            path: "dataset/:datasetId",
            element: <DatasetDetailsPage />,
            loader: async ({ params }) =>
              json(await Apis.shared().project.fetchDatasets(params.projectId!, params.datasetId!)),
          },
          {
            path: "processor",
            element: <ProcessorsPage />,
            loader: async ({ params }) => json(await Apis.shared().project.fetchProcessors(params.projectId!)),
          },
          {
            path: "processor/:processorId",
            element: <ProcessorDetailsPage />,
            loader: async ({ params }) =>
              json(await Apis.shared().project.fetchProcessor(params.projectId!, params.processorId!)),
          },
          {
            path: "exploration",
            element: <ExplorationPage />,
            loader: async ({ params }) => json(await Apis.shared().project.fetchExplorations(params.projectId!)),
          },
          {
            path: "exploration/:explorationId",
            element: <ExplorationDetailsPage />,
            loader: async ({ params }) => {
              const exploration = await Apis.shared().project.fetchExploration(params.projectId!, params.explorationId!);
              const statistics = await Apis.shared().data.queryStatistics(params.projectId!, exploration.dataset_id, exploration.dataset!.filename!)
              return json({ exploration, statistics });
            }
          },
          {
            path: "prompt",
            element: <PromptsPage />,
            loader: async ({ params, request }) => {
              const sp = new URL(request.url);
              const type = sp.searchParams.get('type') ?? undefined;
              return json(await Apis.shared().project.fetchPrompts(params.projectId!, type));
            }
          },
          {
            path: "prompt/:promptId",
            element: <PromptDetailsPage />,
            loader: async ({ params }) => {
              const exploration = await Apis.shared().project.fetchPrompt(params.projectId!, params.promptId!);
              return json(exploration);
            }
          },
        ],
      },
      {
        path: "",
        element: <Navigate to="/overview" />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
