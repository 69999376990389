import { Backdrop, Box, CircularProgress, Fade, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";

type Props = {
  message?: string;
  active: boolean;
};

const LoaderBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  display: "flex",
  justifyContent: "space-around",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  zIndex: 100,
  background: theme.palette.overlay,
  transition: "opacity 0.2s 0.5s ease-out",
  alignItems: "center",
  opacity: 0,
  "&.[data-active]": {
    opacity: 1,
  },
}));

export function ModalLoader(props: Props) {
  const { message, active } = props;
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    setVisible(active);
  }, [active]);

  return active ? (
    <Fade in={!!active} timeout={{enter: 2500, exit: 200, appear: 500 }}>
      <Backdrop open={!!active}>
        <Stack direction="column" alignItems="center" spacing={2}>
          <CircularProgress />
          {message && (
            <Typography variant="h6" sx={{ color: "white" }}>
              {message}
            </Typography>
          )}
        </Stack>
      </Backdrop>
    </Fade>
  ) : (
    <></>
  );
}
