import { createAuthRefreshFetchFn, createSignedRefreshLinkFn, FetchStatus } from "../components/auth/auth";
import { ApiConfig } from "../state/applicationconfig";
import { AuthApi } from "./impl/auth";
import { DataApi } from "./impl/data";
import { LangApi } from "./impl/lang";
import { MetadataApi } from "./impl/metadata";
import { ProjectApi } from "./impl/project";
import { FetchFn, IAuthApi, IDataApi, ILangApi, IMetadataApi, IProjectApi, SignedLinkFn } from "./types";

export class Apis {
  private _config: ApiConfig;
  private _auth: IAuthApi;
  private _metadata: IMetadataApi;
  private _project: IProjectApi;
  private _data: IDataApi;
  private _lang: ILangApi;
  private _authenticatedFetchFn: FetchFn;
  private _signedLinkFn: SignedLinkFn;
  private _globalStatusFn: (status: FetchStatus) => void = () => {};

  get auth(): IAuthApi {
    return this._auth;
  }
  get metadata(): IMetadataApi {
    return this._metadata;
  }
  get project(): IProjectApi {
    return this._project;
  }
  get data() : IDataApi {
    return this._data;
  }
  get lang(): ILangApi {
    return this._lang;
  }

  public set globalStatusFn(fn: (status: FetchStatus) => void) {
    this._globalStatusFn = fn;
  }
  
  constructor(config: ApiConfig, token: string | null | undefined, refreshToken: string | null | undefined) {
    this._config = config;
    this._auth = new AuthApi(this._config.endpoints.auth);
    this._authenticatedFetchFn = createAuthRefreshFetchFn(this._auth, token ?? "", refreshToken ?? "", (status: FetchStatus) => { this._globalStatusFn(status) });
    this._signedLinkFn = createSignedRefreshLinkFn(this._auth, token ?? "", refreshToken ?? "");
    this._project = new ProjectApi(this._config.endpoints.project, this._authenticatedFetchFn, this._signedLinkFn);
    this._data = new DataApi(this._config.endpoints.data, this._authenticatedFetchFn);
    this._metadata = new MetadataApi(this._config.endpoints.metadata, this._authenticatedFetchFn);
    this._lang = new LangApi(this._config.endpoints.lang, this._authenticatedFetchFn);
  }

  setAuthentication(token: string | null | undefined, refreshToken: string | null | undefined) {
    this._authenticatedFetchFn = createAuthRefreshFetchFn(this._auth, token ?? "", refreshToken ?? "", (status: FetchStatus) => { this._globalStatusFn(status) });
    this._signedLinkFn = createSignedRefreshLinkFn(this._auth, token ?? "", refreshToken ?? "");
    this._project = new ProjectApi(this._config.endpoints.project, this._authenticatedFetchFn, this._signedLinkFn);
    this._data = new DataApi(this._config.endpoints.data, this._authenticatedFetchFn);
    this._metadata = new MetadataApi(this._config.endpoints.metadata, this._authenticatedFetchFn);
    this._lang = new LangApi(this._config.endpoints.lang, this._authenticatedFetchFn);
  }

  private static _sharedInstance: Apis | null;
  public static shared(): Apis {
    return this._sharedInstance!;
  }
  public static createShared(config: ApiConfig, token: string | null | undefined, refreshToken: string | null | undefined) {
    this._sharedInstance = new Apis(config, token, refreshToken);
  }
}
