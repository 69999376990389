export function intOrUndefined(value: string | undefined | null): number | undefined {
  if (value === undefined || value === null) {
    return undefined;
  }
  const v = parseInt(value);
  return isNaN(v) ? undefined : v;
}

export function boolStr(value: boolean | undefined): string {
  return value ? 'true' : 'false';
}