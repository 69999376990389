export interface SampleStateSelected {
  selected?: boolean;
}

export interface SampleStateExpanded {
  expanded?: boolean;
}

export interface SampleState extends SampleStateSelected, SampleStateExpanded { }

export interface DataState {
  items: Record<string, SampleState>;
}

export enum DataStateActionTypes {
  loadUntypedState,
  replaceState,
  setSelected,
  clearSelected,
  setExpanded,
}

export interface DataStateAction {
  type: DataStateActionTypes;
}

export interface ReplaceStateAction extends DataStateAction {
  type: DataStateActionTypes.replaceState;
  state?: DataState;
}

// Used to be 
export interface LoadUntypedStateAction extends DataStateAction {
  type: DataStateActionTypes.loadUntypedState;
  state?: Record<string, any>;
}

export interface SetSelectedAction extends DataStateAction {
  type: DataStateActionTypes.setSelected;
  ids: string[];
  selected: boolean;
}

export interface ClearSelectedAction extends DataStateAction {
  type: DataStateActionTypes.clearSelected;
}

export interface SetExpandedAction extends DataStateAction {
  type: DataStateActionTypes.setExpanded;
  ids: string[];
  expanded: boolean;
}

export type AnyDataStateAction = SetSelectedAction | SetExpandedAction | ReplaceStateAction | LoadUntypedStateAction | ClearSelectedAction;

export function createEmptyDataState(): DataState {
  const items: Record<string, SampleState> = {};
  return { items };
}

export function dataStateReducer(state: DataState, action: AnyDataStateAction) {
  const items = { ...state.items };
  switch (action.type) {
    case DataStateActionTypes.setSelected:
      const { selected } = action;
      action.ids.forEach((id) => {
        const item = { ...items[id], selected };
        items[id] = item;
      });
      return { ...state, items };
    case DataStateActionTypes.setExpanded:
      const { expanded } = action;
      action.ids.forEach((id) => {
        const item = { ...items[id], expanded };
        items[id] = item;
      });
      return { ...state, items };
    case DataStateActionTypes.replaceState:
      return action.state ?? createEmptyDataState();
    case DataStateActionTypes.clearSelected:
      Object.keys(items).forEach((k) => {
        delete items[k].selected;
      });
      return { ...state, items };
    case DataStateActionTypes.loadUntypedState:
      const newState = (action.state ?? createEmptyDataState()) as DataState;
      return newState;
  }
  throw new Error("Unknown action.");
}

// Strip things we do not want to serialize here

export function serializeDataState(state: DataState): any {
  const items: Record<string, SampleStateSelected> = {};
  Object.keys(state.items).forEach((k) => {
    if (state.items[k]?.selected) {
      items[k] = {selected: true};
    }
  });
  return {...state, items};
}

export function selectedSampleIds(state: DataState): string[] {
  return Object.keys(state.items).filter((k) => (state.items[k]?.selected));
}