import { Backdrop, Box, Breadcrumbs, CircularProgress, Fade, Stack, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { useState } from "react";
import { useLoaderData, useSearchParams } from "react-router-dom";
import { ExplorationModel } from "../../api/apimodels";
import { Nav } from "../../components/common/Nav";
import * as PageInfo from "../../pages/PageInfo";
import { ExplorationDetailsDataTab } from "./details/ExplorationDataTab";
import { ExplorationInfoTab } from "./details/ExplorationInfoTab";

export const ExplorationDetailsPage = () => {
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { exploration } = useLoaderData() as { exploration: ExplorationModel };
  const section = searchParams.get('section') ?? 'data';

  const setSection = (section: string) => {
    const newParams = new URLSearchParams(searchParams);
    newParams.set('section', section);
    setSearchParams(newParams);
  };

  return  <Box sx={{ display: "flex" }}>
  <Box
    component="main"
    sx={{
      flexGrow: 1,
      overflow: "auto",
    }}
  >
    <Stack direction="row" justifyContent="space-between" alignItems="baseline">
      <Breadcrumbs aria-label="breadcrumb" sx={{ ml: 3, mt: 5 }}>
        <Nav to="./..">{PageInfo.ExplorationPage.name}</Nav>
        <Typography color="text.primary">{exploration.name}</Typography>
      </Breadcrumbs>
      <ToggleButtonGroup size="small" sx={{mr: 3}} exclusive value={section} onChange={(e, s) => setSection(s)}>
        <ToggleButton value="info">Info</ToggleButton>
        <ToggleButton value="data">Data</ToggleButton>
      </ToggleButtonGroup>
    </Stack>

    {section === 'data' && (
      <ExplorationDetailsDataTab loading={loading} setLoading={setLoading} />
    )}
    {section === 'info' && (
      <ExplorationInfoTab loading={loading} setLoading={setLoading} />
    )}
    </Box>
      <Fade in={!!loading} timeout={{enter: 3000, exit: 200 }}>
        <Backdrop open={!!loading}>
          <CircularProgress color="inherit" sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} />
        </Backdrop>
      </Fade>
    </Box>
};