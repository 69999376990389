import { FC, useEffect, useMemo, useState } from "react";
import { useNavigate, useRouteLoaderData } from "react-router-dom";
import * as PageInfo from "../PageInfo";
import { ProjectModel } from "../../api/apimodels";
import { Box, Button, Card, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Stack, SxProps, TextField, Theme, Typography } from "@mui/material";
import ResourceList from "../../components/common/ResourceList";
import { CreateDatasetForm } from "../dataset/DatasetPage";
import { CreateProcessorForm } from "../processor/ProcessorsPage";
import { CreateExplorationsForm } from "../explorations/ExplorationsPage";
import { sortedResource } from "../../util/sorting";
import { StandardCard } from "../../components/common/StandardCard";
import { Apis } from "../../api/apis";

type EditProjectInfoDialogProps = {
  open: boolean;
  dialogTitle: string;
  name?: string;
  subtitle?: string;
  description?: string;
  canDelete: boolean;
  onClose: () => void;
  onDelete: () => void;
  onSave: (name: string, subtitle: string, description: string) => void;
}

export function EditProjectInfoDialog(props: EditProjectInfoDialogProps) {
  const { open, onClose, onSave, canDelete, onDelete, dialogTitle, name, subtitle, description } = props;
  const [projectName, setProjectName] = useState(name ?? "");
  const [projectSubtitle, setProjectSubtitle] = useState(subtitle ?? "");
  const [projectDescription, setProjectDescription] = useState(description ?? "");
  useEffect(() => {
    if (open) {
      setProjectName(name ?? "");
      setProjectDescription(description ?? "");
    } else {
      setProjectName("");
      setProjectName("");
    }
  }, [open, name, description]);

  return (
      <Dialog
          open={open}
          onClose={onClose}
          PaperProps={{
              component: 'form',
              onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                  event.preventDefault();
                  onSave(projectName, projectSubtitle, projectDescription);
              },
          }}
      >
          <DialogTitle>{dialogTitle}</DialogTitle>
          <DialogContent>
              <TextField required margin="dense" id="name" name="name" label="Name" type="text" fullWidth value={projectName} onChange={(e) => { setProjectName(e.target.value); }} variant="standard" />
              <TextField margin="dense" id="subtitle" name="subtitle" label="Subtitle" type="text" fullWidth value={projectSubtitle} onChange={(e) => { setProjectSubtitle(e.target.value); }} variant="standard" multiline />
              <TextField margin="dense" id="description" name="description" label="Description" type="text" value={projectDescription} onChange={(e) => { setProjectDescription(e.target.value); }} variant="standard" fullWidth multiline />
          </DialogContent>
          <DialogActions>
              <Box sx={{flexGrow: 1, ml: 1}}>
                {canDelete && (
                  <Button onClick={onDelete} color="error">Delete project</Button>
                )}
              </Box>
              <Button onClick={onClose}>Cancel</Button>
              <Button type="submit">Save</Button>
          </DialogActions>
      </Dialog>
  );
}


const LabelAndText: FC<{label: string, text: string, sx?: SxProps<Theme>}> = ({label, text, sx}) => {
  return (
    <Stack direction="column" sx={sx}>
      <Typography variant="subtitle2" sx={{opacity: 0.75}}>{label}</Typography>
      <Typography variant="body1">{text}</Typography>
    </Stack>
  )
}

type ProjectInfoProps = {
  project: ProjectModel;
  onEdit: () => void;
  sx?: SxProps<Theme>;
};

const ProjectInfo: FC<ProjectInfoProps> = ({ project, onEdit, sx }) => {
  const { subtitle, description } = project;
  return (
    <StandardCard sx={sx}>
      <Stack sx={{ pb: 2 }} direction="row" justifyContent="space-between">
        <Typography variant="h6">
          Project info
        </Typography>
        <Button variant="text" onClick={onEdit}>Edit</Button>
      </Stack>
      <Stack direction="column" gap={1}>
        <LabelAndText label="Subtitle" text={subtitle ?? ""} sx={{mb: 1}} />
        <LabelAndText label="Description" text={description ?? ""} sx={{mb: 1}} />
      </Stack>
    </StandardCard>
  );
};

export default function ProjectPage() {
  const data = useRouteLoaderData("project") as ProjectModel;
  const navigate = useNavigate();
  const enabledPages = new Set([PageInfo.ExplorationPage.slug, PageInfo.DatasetPage.slug]);
  const explorations = useMemo(() => (sortedResource(data?.explorations ?? [], true)), [data]);
  const datasets = useMemo(() => (sortedResource(data?.datasets ?? [], true)), [data]);
  const processors = useMemo(() => (sortedResource(data?.processors ?? [], true)), [data]);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  
  function onSelectDataset(id: number) {
    navigate("./dataset/" + id);
  }

  function onSelectModel(id: number) {
    navigate("./processor/" + id);
  }

  function onSelectExploration(id: number) {
    navigate("./exploration/" + id);
  }

  function onSaveProjectInfo(name: string, subtitle: string, description: string) {
    Apis.shared().metadata.updateProject(data.id!, name, subtitle, description)
      .then(() => {
        navigate(".");
        setEditDialogOpen(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function onDeleteProject() {
    Apis.shared().metadata.deleteProject(data.id!)
      .then(() => {
        navigate("/");
        setEditDialogOpen(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  return (
    <Box sx={{ display: "flex", "& .MuiCard-root": { mt: 0, mb: 2, width: "100%" } }}>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          overflow: "auto",
        }}
      >
        <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
          <Grid container spacing={3}>
              <Grid item xs={12} md={8} lg={8}>
                <ProjectInfo project={data} onEdit={() => { setEditDialogOpen(true); }}/>
                {enabledPages.has(PageInfo.ExplorationPage.slug) && explorations.length > 0 && (
                  <Card>
                    <ResourceList
                      onSelectResource={onSelectExploration}
                      icon={PageInfo.ExplorationPage.menuIcon}
                      title="Latest explorations"
                      items={explorations}
                      isLoadingFn={(item) => (false)}
                      createLabel="Create new exploration"
                      forceExpand={explorations.length === 0}
                      viewAll={explorations.length > 4}
                      maxItems={4}
                    />
                  </Card>
                )}
                {enabledPages.has(PageInfo.DatasetPage.slug) && datasets.length > 0 && (
                  <Card>
                    <ResourceList
                      onSelectResource={onSelectDataset}
                      icon={PageInfo.DatasetPage.menuIcon}
                      title="Latest datasets"
                      items={datasets}
                      isLoadingFn={(item) => (false)}
                      createLabel="Create new dataset"
                      forceExpand={datasets.length === 0}
                      viewAll={datasets.length > 4}
                      maxItems={4}
                    />
                  </Card>
                )}
                {enabledPages.has(PageInfo.ProcessorPage.slug) && processors.length > 0 && (
                  <Card>
                    <ResourceList
                      onSelectResource={onSelectModel}
                      icon={PageInfo.ProcessorPage.menuIcon}
                      title="Latest models"
                      items={processors}
                      isLoadingFn={(item) => (false)}
                      createLabel="Create new model"
                      forceExpand={processors.length === 0}
                      viewAll={processors.length > 4}
                      maxItems={4}
                    />
                  </Card>
                )}
              </Grid>
            <Grid item xs={12} md={4} lg={4}>
              {enabledPages.has(PageInfo.ExplorationPage.slug) && datasets.length > 0 && processors.length > 0 && <CreateExplorationsForm />}
              {enabledPages.has(PageInfo.DatasetPage.slug) && datasets.length === 0 && <CreateDatasetForm />}
              {enabledPages.has(PageInfo.ProcessorPage.slug) && processors.length === 0 && <CreateProcessorForm />}
            </Grid>
          </Grid>
        </Container>
      </Box>
      <EditProjectInfoDialog 
        open={editDialogOpen} 
        dialogTitle={"Edit Project"}
        name={data.name}
        subtitle={data.subtitle}
        description={data.description}
        onClose={() => {setEditDialogOpen(false)}} 
        canDelete={data.datasets?.length === 0 || data.processors?.length === 0}
        onDelete={onDeleteProject} 
        onSave={onSaveProjectInfo} 
        />
    </Box>
  );
}
