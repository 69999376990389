import { boolStr } from "../../util/params";
import { ApiId, DataSamplesResult, DataStatisticsResult, ExplorationFilter } from "../apimodels";
import { standardPostOptions } from "../helpers";
import { FetchFn, IDataApi } from "../types";

export class DataApi implements IDataApi {
  private baseUrl: string;
  private fetchFn: FetchFn;
  public ver: number;

  constructor(baseUrl: string, fetchFn: FetchFn | undefined) {
    this.baseUrl = baseUrl;
    this.fetchFn = fetchFn ?? ((input, init) => fetch(input, init));
    this.ver = Math.random() * 10000000;
  }

  async queryStatistics(projectId: ApiId, datasetId: ApiId, filename: string, filter?: ExplorationFilter, includeLabels?: boolean, includeThemeTopicFrequency?: boolean, includeWordsStats?: boolean): Promise<DataStatisticsResult> {
    const u = new URL(`${this.baseUrl}/query/statistics`);
    u.searchParams.set('project_id', `${projectId}`);
    u.searchParams.set('dataset_id', `${datasetId}`);
    u.searchParams.set('filename', filename);
    u.searchParams.set('include_labels', boolStr(includeLabels));
    u.searchParams.set('include_theme_topic_freq', boolStr(includeThemeTopicFrequency));
    u.searchParams.set('include_word_stats', boolStr(includeWordsStats));
    const url = u.toString();
    const response = await this.fetchFn(url, standardPostOptions({ body: JSON.stringify({ filter: filter ?? null }) }));
    const data = await response.json();
    return data as DataStatisticsResult;
  }

  async querySamples(projectId: ApiId, datasetId: ApiId, filename: string, start: number, count: number, filter?: ExplorationFilter, allColumns?: boolean): Promise<DataSamplesResult> {
    const u = new URL(`${this.baseUrl}/query/samples`);
    u.searchParams.set('project_id', `${projectId}`);
    u.searchParams.set('dataset_id', `${datasetId}`);
    u.searchParams.set('filename', filename);
    u.searchParams.set('all_columns', boolStr(allColumns));
    const url = u.toString();
    const response = await this.fetchFn(url, standardPostOptions({
      body: JSON.stringify({
        filter: filter ?? null,
        start,
        count
      })
    }));
    const data = await response.json();
    return data as DataSamplesResult;
  }
}