import { StandardItemModel } from "../api/apimodels";

export function sortedResource<T extends StandardItemModel>(items: T[], reverse: boolean = false): T[] {
  return reverse ? 
    items.toSorted((a, b) => (
      (new Date(b.created_at ?? "")).getTime() - 
      (new Date(a.created_at ?? "")).getTime())
    ) : 
    items.toSorted((a, b) => (
      (new Date(a.created_at ?? "")).getTime() - 
      (new Date(b.created_at ?? "")).getTime())
    );
}